import { useState } from "react";
import { toast } from "react-toastify";
import {
  VuiModal,
  VuiTextInput,
  VuiTextArea,
  VuiFormGroup,
  VuiButtonPrimary,
  VuiFlexItem,
  VuiSpacer
} from "@vectara/vectara-ui";
import { useLoginContext } from "../../../utils/LoginContext";
import { Role } from "../../../utils/commonTypes";
import { AiOutlinePlus } from "react-icons/ai";
import { createUser } from "../../../api/api";

interface AddUserModalProps {
  isOpen: boolean;
  roles: Role[];
  onClose: () => void;
  onUserAdded: () => Promise<void>;
}

export const AddUserModal: React.FC<AddUserModalProps> = ({ isOpen, roles, onClose, onUserAdded }) => {
  const { authToken } = useLoginContext();
  const [newUsername, setNewUsername] = useState("");
  const [newOrganization, setNewOrganization] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [selectedRole, setSelectedRole] = useState<string>("");

  const resetForm = () => {
    setNewUsername("");
    setNewOrganization("");
    setNewDescription("");
    setSelectedRole("");
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleAddUser = async () => {
    if (!newUsername) {
      toast.error("Username is required");
      return;
    }

    try {
      // If a role is selected, send it as a single-element array
      const rolesToSend = selectedRole ? [selectedRole] : [];
      await createUser(authToken, newUsername, newOrganization, newDescription, rolesToSend);
      toast.success("User created successfully");

      // Refresh users list
      await onUserAdded();

      // Close modal
      handleClose();
    } catch (error) {
      console.error("Error creating user:", error);
      toast.error(error instanceof Error ? error.message : "Failed to create user");
    }
  };

  return (
    <VuiModal title="Add New User" isOpen={isOpen} onClose={handleClose} icon={<AiOutlinePlus />}>
      <VuiFormGroup label="Username" labelFor="username">
        <VuiTextInput
          placeholder="user@example.com"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
        />
      </VuiFormGroup>

      <VuiSpacer size="m" />

      <VuiFormGroup label="Organization" labelFor="organization">
        <VuiTextInput
          placeholder="User's organization"
          value={newOrganization}
          onChange={(e) => setNewOrganization(e.target.value)}
        />
      </VuiFormGroup>

      <VuiSpacer size="m" />

      <VuiFormGroup label="Description" labelFor="description">
        <VuiTextArea
          placeholder="Brief description about the user"
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
        />
      </VuiFormGroup>

      <VuiSpacer size="m" />

      <VuiFormGroup label="Role" labelFor="role">
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {roles.map((role) => (
            <div key={role.id} style={{ marginBottom: "8px" }}>
              <input
                type="radio"
                id={`role-${role.id}`}
                name="role"
                value={role.name}
                checked={selectedRole === role.name}
                onChange={() => setSelectedRole(role.name)}
                style={{ marginRight: "8px" }}
              />
              <label htmlFor={`role-${role.id}`}>{`${role.name} (${role.description})`}</label>
            </div>
          ))}
        </div>
      </VuiFormGroup>

      <VuiSpacer size="m" />

      <VuiFlexItem>
        <VuiButtonPrimary color="neutral" onClick={handleAddUser}>
          Create
        </VuiButtonPrimary>
      </VuiFlexItem>
    </VuiModal>
  );
};

export default AddUserModal;
