import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField
} from "@mui/material";
import {
  VuiButtonSecondary,
  VuiCopyButton,
  VuiFlexContainer,
  VuiHorizontalRule,
  VuiIcon,
  VuiSpacer,
  VuiSpinner,
  VuiTable,
  VuiText
} from "@vectara/vectara-ui";
import { Prompt, PromptType } from "../../utils/commonTypes";
import { useEffect, useState } from "react";
import { createPrompt, listPrompts } from "../../api/api";
import { useCustomerContext } from "../../utils/CustomerContext";
import { useLoginContext } from "../../utils/LoginContext";
import { displayToast } from "../../utils/errorHandler";
import { BiCheck, BiError, BiHourglass, BiQuestionMark } from "react-icons/bi";

const defaultPrompt = // eslint-disable-next-line
  '[ {"role": "system", "content": "You are a search bot that takes search results and summarizes them as a coherent answer. Give slight preference to search results that appear earlier in the chat. Only use information provided in this chat."}, #foreach ($qResult in $vectaraQueryResults) #if ($foreach.first) {"role": "user", "content": "Search for \\"$esc.java(${vectaraQuery})\\", and give me the first search result."}, {"role": "assistant", "content": "$esc.java(${qResult.getText()})" }, #else {"role": "user", "content": "Give me the $vectaraIdxWord[$foreach.index] search result."}, {"role": "assistant", "content": "$esc.java(${qResult.getText()})" }, #end #end {"role": "user", "content": "Generate a comprehensive and informative answer (but no more than $vectaraOutChars characters) for the query \\"$esc.java(${vectaraQuery})\\" solely based on the search results in this chat. You must only use information from the provided results. Combine search results together into a coherent answer. Do not repeat text. Cite search results using [number] notation. Only cite the most relevant results that answer the question accurately. If the search results are not valid, respond with \\"The returned results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.\\". Respond in the language denoted by ISO 639 code \'$vectaraLangCode\'."} ]';

type PromptStatus = "GOOD" | "BAD" | "UNKNOWN" | "TESTING";

export const CustomerPrompts = () => {
  const [prompts, setPrompts] = useState<Array<Prompt>>([]);
  const [isPromptsLoading, setIsPromptsLoading] = useState<boolean>(true);
  const [createPromptOpen, setCreatePromptOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [newPrompt, setNewPrompt] = useState(defaultPrompt);
  const [modelName, setModelName] = useState("gpt-3.5-turbo");
  const [type, setType] = useState<PromptType>("VELOCITY");
  const [temperature, setTemperature] = useState(0.7);
  const [maxTokens, setMaxTokens] = useState(1024);
  const [topP, setTopP] = useState(1);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [promptStatus, setPromptStatus] = useState<PromptStatus>("UNKNOWN");
  const [creating, setCreating] = useState<boolean>(false);

  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();

  const allModels = prompts?.map((x) => x.model);
  const modelIds = allModels?.map((x) => x.modelId);
  // Remove duplicates
  const models = allModels.filter(({ modelId }, index) => !modelIds.includes(modelId, index + 1));

  const fetchPrompts = async () => {
    setIsPromptsLoading(true);
    try {
      setPrompts(await listPrompts(customer.customerId, authToken));
      setIsPromptsLoading(false);
    } catch (e) {
      console.error(e);
      setIsPromptsLoading(false);
    }
  };

  useEffect(() => {
    fetchPrompts();
    // eslint-disable-next-line
  }, [authToken, customer.customerId]);

  if (isPromptsLoading) {
    return (
      <VuiText>
        Loading prompts, please wait ...
        <VuiSpinner size="m" />
      </VuiText>
    );
  }

  const columns = [
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (prompt: Prompt) => {
        if (!prompt) return;
        return <>{prompt.name}</>;
      }
    },
    {
      name: "description",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (prompt: Prompt) => {
        if (!prompt) return;
        return (
          <>
            {prompt.description} <VuiCopyButton size="xs" value={prompt.description} />
          </>
        );
      }
    },
    {
      name: "model",
      header: {
        render: () => {
          return "Model";
        }
      },
      render: (prompt: Prompt) => {
        if (!prompt) return;
        return <>{prompt.model.modelName}</>;
      }
    },
    {
      name: "prompt",
      header: {
        render: () => {
          return "Prompt";
        }
      },
      render: (prompt: Prompt) => {
        if (!prompt) return;
        return (
          <>
            {prompt.prompt.text} <VuiCopyButton size="xs" value={prompt.prompt.text} />
          </>
        );
      }
    },
    {
      name: "default",
      header: {
        render: () => {
          return "Default";
        }
      },
      render: (prompt: Prompt) => {
        if (!prompt) return;
        return <>{prompt.isDefault ? <BiCheck className="ml-5" /> : ""}</>;
      }
    }
  ];

  const tableContent = !prompts?.length ? (
    <VuiText>
      <p>No prompts found.</p>
    </VuiText>
  ) : undefined;

  const getModelNames = () => {
    if (!models) {
      return [];
    }
    // Return all the IDs in an array.
    return models.map((x) => x.modelName);
  };

  const getModelIdFromName = (name: string) => {
    return models.find((x) => x.modelName === name)?.modelId;
  };

  const handleClickOpen = () => {
    setCreatePromptOpen(true);
  };

  const handleClose = () => {
    setCreatePromptOpen(false);
  };

  const promptIcon =
    promptStatus === "UNKNOWN" ? (
      <VuiIcon size="m" color="success">
        <BiQuestionMark />
      </VuiIcon>
    ) : promptStatus === "GOOD" ? (
      <VuiIcon size="m" color="subdued">
        <BiCheck />
      </VuiIcon>
    ) : promptStatus === "TESTING" ? (
      <VuiIcon size="m" color="subdued">
        <BiHourglass />
      </VuiIcon>
    ) : (
      <VuiIcon size="m" color="danger">
        <BiError />
      </VuiIcon>
    );

  const handleCreate = async (testOnly: boolean) => {
    if (!testOnly && (!name || !description || !newPrompt)) {
      displayToast("Please fill out all required fields.");
      return;
    }
    setCreating(true);
    if (testOnly) setPromptStatus("TESTING");
    try {
      const modelId = getModelIdFromName(modelName);
      await createPrompt(
        customer.customerId,
        authToken,
        name,
        description,
        modelId || 1,
        modelName,
        type,
        newPrompt,
        testOnly,
        {
          temperature: temperature,
          max_tokens: maxTokens,
          top_p: topP,
          frequency_penalty: frequencyPenalty,
          presence_penalty: presencePenalty
        }
      );
      if (testOnly) {
        setPromptStatus("GOOD");
        displayToast("Prompt is valid.");
      } else {
        fetchPrompts();
        setCreatePromptOpen(false);
        displayToast("Prompt created successfully.");
      }
    } catch (e) {
      if (testOnly) setPromptStatus("BAD");
      console.error(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <>
      <VuiFlexContainer className="text-base">
        <Button variant="outlined" onClick={handleClickOpen}>
          Create new Prompt
        </Button>
        <Dialog fullWidth maxWidth="lg" open={createPromptOpen} onClose={handleClose}>
          <DialogTitle>Create new Prompt</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This prompt will be added to the customer database, and will be available for use in the system.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              required
              id="desc"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              multiline
              required
              rows={10}
              margin="dense"
              id="prompt"
              label="Prompt"
              value={newPrompt}
              onChange={(e) => {
                setPromptStatus("UNKNOWN");
                setNewPrompt(e.target.value);
              }}
              fullWidth
              variant="standard"
            />
            <FormControl sx={{ width: "30ch", mt: 2 }}>
              <Autocomplete
                size="small"
                id="modelName"
                options={getModelNames()}
                value={modelName}
                renderInput={(params) => <TextField {...params} label="Model Name" />}
                onChange={(e, value) => setModelName(value || "gpt-3.5-turbo")}
              />
            </FormControl>
            <FormControl sx={{ width: "20ch", ml: 2, mt: 2 }}>
              <Autocomplete
                size="small"
                id="type"
                options={["PLAIN", "VELOCITY"]}
                value={type}
                renderInput={(params) => <TextField {...params} label="Prompt Type" />}
                onChange={(e, value) => setType((value as PromptType) || "VELOCITY")}
              />
            </FormControl>
            <FormControl sx={{ width: "20ch", ml: 4, mt: 2.3 }}>
              <VuiButtonSecondary
                onClick={() => handleCreate(true)}
                color="primary"
                icon={promptIcon}
                isDisabled={creating}
              >
                Test Prompt
              </VuiButtonSecondary>
            </FormControl>
            <VuiSpacer size="m" />
            <VuiHorizontalRule />
            <VuiSpacer size="s" />
            <DialogContentText>Configuration Parameters:</DialogContentText>
            <TextField
              sx={{ width: "15ch", mt: 2 }}
              margin="dense"
              id="temperature"
              label="Temperature"
              value={temperature}
              onChange={(e) => setTemperature(Number(e.target.value))}
              variant="standard"
            />
            <TextField
              sx={{ width: "15ch", mt: 2, ml: 2 }}
              margin="dense"
              id="maxTokens"
              label="Max Tokens"
              value={maxTokens}
              onChange={(e) => setMaxTokens(Number(e.target.value))}
              variant="standard"
            />
            <TextField
              sx={{ width: "15ch", mt: 2, ml: 2 }}
              margin="dense"
              id="topP"
              label="Top P"
              value={topP}
              onChange={(e) => setTopP(Number(e.target.value))}
              variant="standard"
            />
            <TextField
              sx={{ width: "15ch", mt: 2 }}
              margin="dense"
              id="freqPenalty"
              label="Frequency Penalty"
              value={frequencyPenalty}
              onChange={(e) => setFrequencyPenalty(Number(e.target.value))}
              variant="standard"
            />
            <TextField
              sx={{ width: "15ch", mt: 2, ml: 2 }}
              margin="dense"
              id="presencePenalty"
              label="Presence Penalty"
              value={presencePenalty}
              onChange={(e) => setPresencePenalty(Number(e.target.value))}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleCreate(false)}>Create</Button>
          </DialogActions>
        </Dialog>
      </VuiFlexContainer>
      <VuiSpacer size="m" />
      <VuiTable
        fluid
        idField={(prompt: Prompt) => prompt.name.toString()}
        columns={columns}
        rows={prompts}
        content={tableContent}
      />
    </>
  );
};
