import { toast } from "react-toastify";

export const handleErrors = async (status: number, text: string) => {
  if (status === 401) {
    toast.error("Your login seems to have expired. Please re-login.");
    return;
  }
  if (status === 403) {
    toast.error("You are not authorized to perform this action. Please contact your Administrator.");
    return;
  }
  if (status !== 200) {
    toast.error(text);
    return;
  }
};

export const displayToast = (text: string) => {
  toast.info(text);
};
