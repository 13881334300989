import { Routes, Route, Navigate } from "react-router-dom";
import "./TabbedRoutes.scss";
import { VuiSpacer, VuiTab, VuiTabs } from "@vectara/vectara-ui";
import { useVuiContext } from "@vectara/vectara-ui/lib/components/context/Context";

type Props = {
  routes: Array<{
    href: string;
    title: string;
    render?: (tabLink: React.ReactNode) => React.ReactNode;
    component: React.ReactNode;
    testId?: string;
  }>;
  defaultRoute: string;
  sideContent?: React.ReactNode;
};

export const TabbedRoutes = ({ routes, defaultRoute, sideContent }: Props) => {
  const { getPath } = useVuiContext();

  return (
    <div className="tabbed-routes">
      <VuiTabs append={sideContent} size="m">
        {routes.map(({ href, title, render, testId }, index) => {
          const isActive = getPath().includes(href);
          const tabLink = (
            <VuiTab key={index} href={href} isActive={isActive} data-testid={testId}>
              {title}
            </VuiTab>
          );
          if (render) return render(tabLink);
          return tabLink;
        })}
      </VuiTabs>

      <VuiSpacer size="m" />

      <div>
        <Routes>
          {routes.map((route, index) => (
            <Route key={`tab-item-${index}`} path={route.href} element={route.component} />
          ))}
          <Route path="*" element={<Navigate to={defaultRoute || "/"} />}></Route>
        </Routes>
      </div>
    </div>
  );
};
