import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { VuiFlexContainer, VuiButtonPrimary, VuiSpinner, VuiFormGroup, VuiSpacer } from "@vectara/vectara-ui";
import dayjs from "dayjs";
import { FormEvent, useState } from "react";
import { msToSec, secToMs } from "../../utils/commonFunctions";
import { extendCustomerFreeTrial } from "../../api/api";
import { useCustomerContext } from "../../utils/CustomerContext";
import { useLoginContext } from "../../utils/LoginContext";
import { CustomerStatus, Plan } from "../../utils/commonTypes";
import { toast } from "react-toastify";
import ReactJson, { InteractionProps } from "react-json-view";

type Props = {
  activePlan: Plan;
  onSuccess: () => void;
};

export const CustomerPlanExtendFreeTrial = ({ activePlan, onSuccess }: Props) => {
  const { customer, updateCustomer } = useCustomerContext();
  const { authToken } = useLoginContext();
  const [isExtendingPlan, setIsExtendingPlan] = useState<boolean>(false);
  const [newExpiryDate, setNewExpiryDate] = useState<dayjs.Dayjs | null>();
  const [planSpec, setPlanSpec] = useState(JSON.parse(activePlan.planJson));

  const extendPlan = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!newExpiryDate) {
      toast.error("Expiry must not be empty");
      return;
    }

    const expiryEpochSecond = msToSec(newExpiryDate.toDate().getTime());

    try {
      setIsExtendingPlan(true);
      await extendCustomerFreeTrial(authToken, customer.customerId, expiryEpochSecond, JSON.stringify(planSpec));
      setNewExpiryDate(null);
      updateCustomer({ ...customer, status: CustomerStatus.ACT });
      toast.success("Free trial extended");
      onSuccess();
    } finally {
      setIsExtendingPlan(false);
    }
  };

  const onEdit = async (edit: InteractionProps) => {
    setPlanSpec(edit.updated_src);
  };

  return (
    <VuiFlexContainer direction="column" alignItems="center">
      <form onSubmit={extendPlan}>
        <VuiFormGroup label="Expiry" labelFor="expiry" isRequired={true}>
          <div id="expiry">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month", "day"]}
                minDate={activePlan.expiryDate ? dayjs(new Date(secToMs(activePlan.expiryDate))) : dayjs()}
                onChange={setNewExpiryDate}
              />
            </LocalizationProvider>
          </div>
        </VuiFormGroup>
        <VuiSpacer size="m" />
        <VuiFormGroup label={`Plan (${activePlan.planName})`} labelFor="plan">
          <div id="plan">
            <ReactJson
              src={planSpec}
              onEdit={onEdit}
              quotesOnKeys={false}
              enableClipboard={false}
              displayDataTypes={false}
            />
          </div>
        </VuiFormGroup>
        <VuiSpacer size="m" />
        <VuiFlexContainer justifyContent="center">
          <VuiButtonPrimary size="m" color="neutral" isDisabled={!newExpiryDate} isSubmit={true}>
            {isExtendingPlan ? <VuiSpinner size="xs" /> : "Confirm"}
          </VuiButtonPrimary>
        </VuiFlexContainer>
      </form>
    </VuiFlexContainer>
  );
};
