import { toast } from "react-toastify";
import { VuiButtonPrimary, VuiFlexContainer, VuiSpacer, VuiTable, VuiText } from "@vectara/vectara-ui";
import { ScaleCustomer } from "../../utils/commonTypes";
import { setScaleIDs } from "../../utils/storage";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

type Props = {
  scaleCustomers: Array<ScaleCustomer>;
  refresh: () => void;
};

export const ScaleCustomerList = ({ scaleCustomers, refresh }: Props) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState(0);

  const handleAdd = () => {
    // if Id is 0, then don't add
    if (!id || id === 0) {
      toast.error("Please enter a valid customer ID");
      return;
    }
    // if name is empty, then don't add
    if (!name) {
      toast.error("Please enter a valid customer name");
      return;
    }
    const newScaleCustomers = scaleCustomers.concat({ id: id, name: name });
    setScaleIDs(JSON.stringify({ customers: newScaleCustomers }));
    setOpen(false);
    refresh();
  };

  const deleteCustomerFromList = (id: number) => {
    const newScaleCustomers = scaleCustomers.filter((customer) => customer.id !== id);
    setScaleIDs(JSON.stringify({ customers: newScaleCustomers }));
    refresh();
  };

  const actions = [
    {
      label: "Remove",
      onClick: (customer: ScaleCustomer) => {
        deleteCustomerFromList(customer.id);
      }
    }
  ];

  const columns = [
    {
      name: "id",
      header: {
        render: () => {
          return "Customer Id";
        }
      },
      render: (customer: ScaleCustomer) => {
        if (!customer) return;
        return <>{customer.id}</>;
      }
    },
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (customer: ScaleCustomer) => {
        if (!customer) return;
        return <>{customer.name}</>;
      }
    }
  ];

  const tableContent = !scaleCustomers?.length ? (
    <VuiText>
      <p>No customers added to the report.</p>
    </VuiText>
  ) : undefined;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="ml-10 mt-5 customer-ids-table">
      <VuiFlexContainer direction="row" justifyContent="spaceBetween">
        <VuiText size="m"> Customers </VuiText>
        <VuiFlexContainer>
          <VuiButtonPrimary size="m" color="neutral" onClick={() => setOpen(true)}>
            Add
          </VuiButtonPrimary>
        </VuiFlexContainer>
      </VuiFlexContainer>
      <VuiSpacer size="m" />
      <VuiTable
        fluid
        idField={(row: ScaleCustomer) => row.id.toString()}
        columns={columns}
        rows={scaleCustomers}
        actions={actions}
        content={tableContent}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a Customer</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            id="desc"
            label="ID"
            value={id}
            onChange={(e) => setId(parseInt(e.target.value))}
            variant="standard"
          />
          <TextField
            fullWidth
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
