import { Customer } from "./commonTypes";

export const getAuthTokenFromStorage = () => {
  return localStorage.getItem("AuthToken");
};

export const setAuthTokenInStorage = (token: string) => {
  localStorage.setItem("AuthToken", token);
};

export const removeAuthTokenFromStorage = () => {
  localStorage.removeItem("AuthToken");
};

export const getScaleIDs = () => {
  return localStorage.getItem("ScaleIDs");
};

export const setScaleIDs = (scaleIDs: string) => {
  localStorage.setItem("ScaleIDs", scaleIDs);
};

export const removeScaleIDs = () => {
  localStorage.removeItem("ScaleIDs");
};

export const setCustomerData = (customer: Customer) => {
  localStorage.setItem("currentCustomer", JSON.stringify(customer));
};

export const getCustomerData = () => {
  const customer = localStorage.getItem("currentCustomer");
  if (customer) {
    try {
      return JSON.parse(customer);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  return null;
};
