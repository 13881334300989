import { ScalePlanParameters } from "../../../utils/commonTypes";
import { VuiFlexContainer, VuiText, VuiTextColor, VuiToggle } from "@vectara/vectara-ui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

type Props = {
  parameters: ScalePlanParameters;
  setParameters: (parameters: ScalePlanParameters) => void;
};

export const ScaleParameters = ({ parameters, setParameters }: Props) => {
  const handlePlanEffectiveFromChange = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    setParameters({ ...parameters, effectiveFromEpochSecond: date.unix() });
  }

  const handleTableExtractionToggle = () => {
    setParameters({ ...parameters, tableExtractionEnabled: !parameters.tableExtractionEnabled });
  }

  return (
    <VuiFlexContainer direction="column" spacing="xl">
      <VuiFlexContainer direction="column" spacing="xs">
        <VuiText>
          <h3>Plan Effective From</h3>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["year", "month", "day"]}
              value={parameters.effectiveFromEpochSecond ? dayjs.unix(parameters.effectiveFromEpochSecond) : dayjs()}
              onChange={handlePlanEffectiveFromChange}
            />
          </LocalizationProvider>
        </VuiText>
      </VuiFlexContainer>
      <VuiFlexContainer direction="column" spacing="xs">
        <VuiText>
          <h3>Table Extraction Enabled</h3>
          <VuiText className="switch-description">
            <VuiTextColor color="accent">
              Note: Toggle is set to current value by default.
            </VuiTextColor>
          </VuiText>
        </VuiText>
        <VuiToggle checked={parameters.tableExtractionEnabled} onChange={handleTableExtractionToggle} />
      </VuiFlexContainer>
    </VuiFlexContainer>
  );
};
