import { useEffect, useState } from "react";
import {
  VuiButtonSecondary,
  VuiFlexContainer,
  VuiIcon,
  VuiSpacer,
  VuiSpinner,
  VuiTable,
  VuiText
} from "@vectara/vectara-ui";
import { Stats } from "../../utils/commonTypes";
import { readCustomerStats } from "../../api/api";
import { useLoginContext } from "../../utils/LoginContext";
import { formatBytes, shortMonthName } from "../../utils/commonFunctions";
import { BiDownload } from "react-icons/bi";

type Props = {
  customerId: number;
  customerName: string;
};

const get2DigitYear = (year?: number) => {
  return year ? year.toString().slice(-2) : "";
};

const removeEmptyStats = (stats: Array<Stats>) => {
  return stats.filter(
    (item) => item.queryCount > 0 || item.bytesIngested > 0 || item.storageUsed > 0 || item.corpora > 0
  );
};

export const CustomerStatsReport = ({ customerId, customerName }: Props) => {
  const [stats, setStats] = useState<Array<Stats>>([]);
  const [isStatsLoading, setIsStatsLoading] = useState<boolean>(true);

  const { authToken } = useLoginContext();

  useEffect(() => {
    const fetchCustomerStats = async () => {
      try {
        setStats(removeEmptyStats(await readCustomerStats(customerId, authToken, 12, false)));
        setIsStatsLoading(false);
      } catch (e) {
        setIsStatsLoading(false);
        console.error(e);
      }
    };
    fetchCustomerStats();
  }, [authToken, customerId]);

  const exportAsCSV = () => {
    // Exports the stats as a CSV file.
    if (!stats?.length) return;
    const csvRows = [];
    // Header rows.
    csvRows.push(`${customerId}, ${customerName}`);
    csvRows.push('"Month","Queries","Summarizations","Ingest","Storage","Corpora"');
    // Data rows.
    stats.forEach((stat) => {
      csvRows.push(
        `"${shortMonthName(stat.month.toLocaleLowerCase())}, ${get2DigitYear(stat.year)}",${stat.queryCount},${
          stat.summarizations
        },${formatBytes(stat.bytesIngested)},${formatBytes(stat.storageUsed)},${stat.corpora}`
      );
    });
    const finalCSV = csvRows.join("\n");
    // Create a blob and download it.
    const blob = new Blob([finalCSV], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(blob));
    link.setAttribute("download", `customer-${customerId}-stats.csv`);
    link.style.visibility = "hidden";
    link.click();
    link.remove();
  };

  const columns = [
    {
      name: "month",
      header: {
        render: () => {
          return "Month";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return (
          <>
            {shortMonthName(stat.month.toLocaleLowerCase())}, {get2DigitYear(stat.year)}
          </>
        );
      }
    },
    {
      name: "queries",
      header: {
        render: () => {
          return "Queries";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return <>{stat.queryCount.toLocaleString()}</>;
      }
    },
    {
      name: "summarizations",
      header: {
        render: () => {
          return "Summarizations";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return <>{stat.summarizations.toLocaleString()}</>;
      }
    },
    {
      name: "ingest",
      header: {
        render: () => {
          return "Ingest";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return <>{formatBytes(stat.bytesIngested)}</>;
      }
    },
    {
      name: "storage",
      header: {
        render: () => {
          return "Storage";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return <>{formatBytes(stat.storageUsed)}</>;
      }
    },
    {
      name: "corpora",
      header: {
        render: () => {
          return "Corpora";
        }
      },
      render: (stat: Stats) => {
        if (!stat) return;
        return <>{stat.corpora.toLocaleString()}</>;
      }
    }
  ];

  const tableContent = !stats?.length ? (
    <VuiText>
      <p>No customers added to the report.</p>
    </VuiText>
  ) : undefined;

  if (isStatsLoading && !stats?.length) {
    return (
      <div className="rounded-t-xl overflow-hidden pt-5 pl-10">
        <p>
          <i>Loading stats, please wait ...</i>
        </p>
        <VuiSpinner size="m"></VuiSpinner>
      </div>
    );
  }

  return (
    <VuiFlexContainer direction="column">
      <VuiFlexContainer direction="row">
        <VuiButtonSecondary
          size="s"
          onClick={exportAsCSV}
          color="neutral"
          icon={
            <VuiIcon size="m">
              <BiDownload />
            </VuiIcon>
          }
        >
          Download as CSV
        </VuiButtonSecondary>
      </VuiFlexContainer>
      <VuiSpacer size="m" />
      <VuiTable
        fluid
        idField={(stat: Stats) => stat.month.toString()}
        columns={columns}
        rows={stats}
        content={tableContent}
      />
      <VuiSpacer size="s" />
    </VuiFlexContainer>
  );
};
