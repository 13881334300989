import { Autocomplete, Box, FormControl, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { PricingPlan, PricingPlanPeriod } from "../../utils/commonTypes";
import { PlanActions, PlanItem } from "./CreatePlanConstants";
import { VuiFlexContainer, VuiFlexItem, VuiText } from "@vectara/vectara-ui";
import { BytesToMebiBytes, MebiBytesToBytes } from "../../utils/commonFunctions";

type Props = {
  pricingPlan: PricingPlan;
  updatePricingPlan: React.Dispatch<PlanActions>;
};

const valueFromPeriod = (period: PricingPlanPeriod): string => {
  if (period.days > 0) {
    return "day";
  } else if (period.months > 0) {
    return "month";
  } else if (period.years > 0) {
    return "year";
  } else {
    return "";
  }
};

export const PlanBundle = ({ pricingPlan, updatePricingPlan }: Props) => {
  return (
    <Box component="form" className="mt-5 ml-2" sx={{ fontSize: 12 }}>
      <VuiText className="font-bold">Bundle specifications</VuiText>
      <VuiFlexItem>
        <VuiFlexContainer className="mt-2">
          <FormControl sx={{ width: "35ch", m: 1 }}>
            <Autocomplete
              id="bundle-period"
              options={["month", "year", "day"]}
              value={valueFromPeriod(pricingPlan.queryStorageIngestBundle?.period || { days: 0, months: 1, years: 0 })}
              renderInput={(params) => <TextField {...params} label="Period" />}
              onChange={(e, value) =>
                updatePricingPlan({ type: "UPDATE_PERIOD", valueType: PlanItem.BUNDLE, period: value || "" })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>
      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-5">
          <FormControl sx={{ width: "20ch", m: 1 }}>
            <InputLabel htmlFor="bundle-queries">Queries in bundle</InputLabel>
            <Input
              id="bundle-queries"
              value={pricingPlan.queryStorageIngestBundle?.queries}
              onChange={(e) => updatePricingPlan({ type: "UPDATE_BUNDLE", field: "queries", value: e.target.value })}
            />
          </FormControl>
          <FormControl sx={{ width: "20ch", m: 1 }}>
            <InputLabel htmlFor="bundle-summarizations">Summarizations in bundle</InputLabel>
            <Input
              id="bundle-summarizations"
              value={pricingPlan.queryStorageIngestBundle?.summarizations}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_BUNDLE", field: "summarizations", value: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "20ch", m: 1 }}>
            <InputLabel htmlFor="bundle-storage">Storage in bundle</InputLabel>
            <Input
              id="bundle-storage"
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              value={BytesToMebiBytes(pricingPlan.queryStorageIngestBundle?.storageBytes || 0)}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_BUNDLE",
                  field: "storageBytes",
                  value: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "20ch", m: 1 }}>
            <InputLabel htmlFor="bundle-ingest">Ingest in bundle</InputLabel>
            <Input
              id="bundle-ingest"
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              value={BytesToMebiBytes(pricingPlan.queryStorageIngestBundle?.ingestBytes || 0)}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_BUNDLE",
                  field: "ingestBytes",
                  value: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>
    </Box>
  );
};
