import { useEffect, useState } from "react";
import { CustomerStatsReport } from "./CustomerStatsReport";
import { VuiFlexContainer, VuiFlexItem, VuiTextColor } from "@vectara/vectara-ui";
import { ScaleCustomer } from "../../utils/commonTypes";
import { ScaleCustomerList } from "./ScaleCustomerList";
import { getScaleIDs } from "../../utils/storage";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BiCaretDown } from "react-icons/bi";

export const ScaleCustomers = () => {
  const [scaleCustomers, setScaleCustomers] = useState<Array<ScaleCustomer>>([]);
  const [expanded, setExpanded] = useState<string | false>("panel1");

  useEffect(() => {
    refreshCustomersList();
  }, []);

  const refreshCustomersList = () => {
    const scaleIDs = getScaleIDs();
    if (!scaleIDs) {
      return;
    }
    // scaleIDs is a JSON string.
    const scaleIDsArray = JSON.parse(scaleIDs);
    if (!scaleIDsArray) {
      setScaleCustomers([]);
      return;
    }
    setScaleCustomers(scaleIDsArray.customers || []);
  };

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <VuiFlexContainer direction="row">
      <VuiFlexContainer direction="column" className="ml-5 mt-3 scale-customer-stats">
        {scaleCustomers.map((scaleCustomer) => (
          <Accordion
            expanded={expanded === scaleCustomer.id.toString()}
            onChange={handleChange(scaleCustomer.id.toString())}
          >
            <AccordionSummary expandIcon={<BiCaretDown />}>
              <VuiTextColor color="primary">
                {scaleCustomer.name} {scaleCustomer.id}
              </VuiTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerStatsReport
                key={scaleCustomer.id}
                customerId={scaleCustomer.id}
                customerName={scaleCustomer.name}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </VuiFlexContainer>
      <VuiFlexItem className="ml-20 mt-10">
        <ScaleCustomerList scaleCustomers={scaleCustomers} refresh={refreshCustomersList} />
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};
