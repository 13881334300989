import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  VuiModal,
  VuiCheckbox,
  VuiFormGroup,
  VuiButtonPrimary,
  VuiFlexItem,
  VuiSpacer
} from "@vectara/vectara-ui";
import { useLoginContext } from "../../../utils/LoginContext";
import { Role, User } from "../../../utils/commonTypes";
import { AiOutlineEdit } from "react-icons/ai";
import { updateUserRoles } from "../../../api/api";

interface EditUserRoleModalProps {
  isOpen: boolean;
  user: User | null;
  roles: Role[];
  onClose: () => void;
  onUserUpdated: () => Promise<void>;
}

export const EditUserRoleModal: React.FC<EditUserRoleModalProps> = ({
  isOpen,
  user,
  roles,
  onClose,
  onUserUpdated
}) => {
  const { authToken } = useLoginContext();
  const [selectedRole, setSelectedRole] = useState<string>("");

  // Update selected role when user changes
  useEffect(() => {
    if (user && user.roles && user.roles.length > 0) {
      setSelectedRole(user.roles[0]); // Take the first role if there are any
    } else {
      setSelectedRole("");
    }
  }, [user]);

  const handleUpdateUserRoles = async () => {
    if (!user) return;

    try {
      // If a role is selected, send it as a single-element array
      const rolesToSend = selectedRole ? [selectedRole] : [];
      await updateUserRoles(authToken, user.id, rolesToSend);
      toast.success("User roles updated successfully");

      // Refresh users list
      await onUserUpdated();

      // Close modal
      onClose();
    } catch (error) {
      console.error("Error updating user roles:", error);
      toast.error(error instanceof Error ? error.message : "Failed to update user roles");
    }
  };

  return (
    <VuiModal
      title={`Edit Role: ${user?.username || ""}`}
      isOpen={isOpen}
      onClose={onClose}
      icon={<AiOutlineEdit />}
    >
      <VuiFormGroup label="Assign Role" labelFor="role">
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {roles.map((role) => (
            <div key={role.id} style={{ marginBottom: "8px" }}>
              <input
                type="radio"
                id={`edit-role-${role.id}`}
                name="editRole"
                value={role.name}
                checked={selectedRole === role.name}
                onChange={() => setSelectedRole(role.name)}
                style={{ marginRight: "8px" }}
              />
              <label htmlFor={`edit-role-${role.id}`}>{`${role.name} (${role.description})`}</label>
            </div>
          ))}
        </div>
      </VuiFormGroup>
      
      <VuiSpacer size="m" />
      
      <VuiFlexItem>
        <VuiButtonPrimary color="neutral" onClick={handleUpdateUserRoles}>
          Update
        </VuiButtonPrimary>
      </VuiFlexItem>
    </VuiModal>
  );
};

export default EditUserRoleModal;