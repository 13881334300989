import { Link } from "react-router-dom";

/**
 * TabLink is the component which appears as a TabHeader
 * and renders Link which redirects user to the provided
 * address when someone clicks on it.
 *
 * @param {object} obj
 * @param {string} obj.to
 * @param {string} obj.title
 * @param {boolean} obj.isActive
 */

type TabLinkProps = {
  to: string;
  title: string;
  isActive: boolean;
};

export const TabLink = ({ to, title, isActive }: TabLinkProps) => {
  return (
    <Link className={`tab-link text-base hover:no-underline ${isActive ? "text-sky-600" : "text-black"}`} to={to}>
      <span className="tab-title">{title}</span>
      <div className={`tab-indicator ${isActive ? "active-tab-indicator" : ""}`}></div>
    </Link>
  );
};
