import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  VuiModal,
  VuiText,
  VuiCheckbox,
  VuiFormGroup,
  VuiButtonPrimary,
  VuiFlexContainer,
  VuiSpacer
} from "@vectara/vectara-ui";
import { useLoginContext } from "../../../utils/LoginContext";
import { Permission, Role } from "../../../utils/commonTypes";
import { AiOutlineEdit } from "react-icons/ai";
import { listPermissions, updateRolePermissions } from "../../../api/api";

interface ManagePermissionsModalProps {
  isOpen: boolean;
  role: Role | null;
  onClose: () => void;
  onRoleUpdated: () => Promise<void>;
}

export const ManagePermissionsModal: React.FC<ManagePermissionsModalProps> = ({
  isOpen,
  role,
  onClose,
  onRoleUpdated
}) => {
  const { authToken } = useLoginContext();
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch permissions when modal opens
  useEffect(() => {
    if (isOpen && role) {
      setSelectedPermissions(role.permissions || []);
      fetchPermissions();
    }
  }, [isOpen, role]);

  const fetchPermissions = async () => {
    setIsLoading(true);
    try {
      const permissionsData = await listPermissions(authToken);
      setPermissions(permissionsData);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      toast.error("Failed to load permissions");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle updating role permissions
  const handleUpdateRolePermissions = async () => {
    if (!role) return;

    try {
      await updateRolePermissions(authToken, role.id, selectedPermissions);
      toast.success("Role permissions updated successfully");

      // Refresh roles list
      await onRoleUpdated();

      // Close modal
      onClose();
    } catch (error) {
      console.error("Error updating role permissions:", error);
      toast.error(error instanceof Error ? error.message : "Failed to update role permissions");
    }
  };

  return (
    <VuiModal
      title={`Manage Permissions: ${role?.name || ""}`}
      isOpen={isOpen}
      onClose={onClose}
      icon={<AiOutlineEdit />}
    >
      <VuiText size="s">
        <p>Select the permissions to assign to this role. Users assigned to this role will have these permissions.</p>
      </VuiText>

      <VuiSpacer size="m" />

      {isLoading ? (
        <VuiText>Loading permissions...</VuiText>
      ) : (
        <VuiFormGroup label="Assign Permissions" labelFor="permissions">
          <div style={{ maxHeight: "350px", overflowY: "auto" }}>
            {permissions.map((permission) => (
              <VuiCheckbox
                key={permission.id}
                label={`${permission.name}${permission.description ? ` - ${permission.description}` : ""}`}
                checked={selectedPermissions.includes(permission.name)}
                onChange={() => {
                  if (selectedPermissions.includes(permission.name)) {
                    // Remove permission
                    setSelectedPermissions(selectedPermissions.filter((p) => p !== permission.name));
                  } else {
                    // Add permission
                    setSelectedPermissions([...selectedPermissions, permission.name]);
                  }
                }}
              />
            ))}
          </div>
        </VuiFormGroup>
      )}

      <VuiSpacer size="m" />

      <VuiFlexContainer justifyContent="end">
        <VuiButtonPrimary color="neutral" onClick={handleUpdateRolePermissions} isDisabled={isLoading}>
          Save Permissions
        </VuiButtonPrimary>
      </VuiFlexContainer>
    </VuiModal>
  );
};

export default ManagePermissionsModal;
