import "./Header.css";
import logo from "../images/logo-vectara.svg";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useLoginContext } from "../utils/LoginContext";
import { useNavigate } from "react-router-dom";
import { authorizeUser } from "../api/api";
import { getAuthTokenFromStorage, removeAuthTokenFromStorage, setAuthTokenInStorage } from "../utils/storage";

export const Header = () => {
  const [userEmail, setUserEmail] = useState("");
  const { setAuthToken, setAuthUser, isAuthenticated, setIsAuthenticated } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = getAuthTokenFromStorage();
    if (idToken && !isAuthenticated) {
      attemptAuthorization(idToken);
    } // eslint-disable-next-line
  }, []);

  const attemptAuthorization = async (idTokenString: string) => {
    try {
      const authUser = await authorizeUser(idTokenString);
      if (authUser) {
        setUserEmail(authUser.email);
        setIsAuthenticated(true);
        setAuthUser(authUser);
        setAuthToken(idTokenString);
        setAuthTokenInStorage(idTokenString);
      }
    } catch (e) {
      clearAuth();
      console.error(e);
    }
  };

  const logoutFromGoogle = () => {
    googleLogout();
    clearAuth();
    navigate("/");
  };

  const clearAuth = () => {
    setIsAuthenticated(false);
    setAuthToken("");
    removeAuthTokenFromStorage();
  };

  const renderLogin = () => {
    if (isAuthenticated) {
      return (
        <div className="text-sm">
          <div className="flex flex-row mt-4 font-semibold lg:mt-0 text-white mr-4">
            <button
              className="flex items-center ml-2 px-3 py-2 border rounded text-white-200 border-white-400 hover:text-teal-400 hover:border-white"
              onClick={logoutFromGoogle}
            >
              Signout {userEmail}
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            attemptAuthorization(credentialResponse.credential || "");
          }}
          onError={() => {
            console.log("Login Failed");
            clearAuth();
          }}
        />
      );
    }
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-purple-600 p-6 mb-5">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img onClick={() => navigate("/")} className="logo-image" alt="Search" width="54" height="54" src={logo}></img>
        <span className="font-semibold text-xl tracking-tight">Vectara Command & Control Center</span>
      </div>
      <div>{renderLogin()}</div>
    </nav>
  );
};
