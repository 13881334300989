import {
  VuiAccordion,
  VuiButtonPrimary,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpacer,
  VuiSpinner,
  VuiText,
  VuiTitle
} from "@vectara/vectara-ui";
import { FaissServer, Plan, ScalePlanDefaults } from "../../utils/commonTypes";
import ReactJson from "react-json-view";
import { useEffect, useState } from "react";
import { displayToast } from "../../utils/errorHandler";
import { readPricingPlans, switchCustomerPlan } from "../../api/api";
import { useLoginContext } from "../../utils/LoginContext";
import { NewPlanConfig } from "./planSwitch/FaissServers";
import { AvailablePlans } from "./planSwitch/AvailablePlans";
import { OverrideDefaults } from "./planSwitch/OverrideDefaults";
import { useCustomerContext } from "../../utils/CustomerContext";
import { CustomerPlanHistory } from "./CustomerPlanHistory";

export const CustomerPlan = () => {
  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const [isFaissAccordionOpen, setIsFaissAccordionOpen] = useState<boolean>(false);
  const [isPlanAccordionOpen, setIsPlanAccordionOpen] = useState<boolean>(false);
  const [isOverrideDefaultsOpen, setIsOverrideDefaultsOpen] = useState<boolean>(false);
  const [selectedServers, setSelectedServers] = useState<Array<FaissServer>>([]);
  const [selectedPlans, setSelectedPlans] = useState<Array<Plan>>([]);
  const [plans, setPlans] = useState<Plan[]>([])
  const [activePlan, setActivePlan] = useState<Plan | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [scalePlanDefaults, setScalePlanDefaults] = useState<ScalePlanDefaults>({
    maxCorpora: 10,
    maxResultRows: 100,
    maxRerankRows: 100,
    maxBytes: 50 * 1024 * 1024 // 50 MBs
  });

  const fetchPlan = async () => {
    setIsLoading(true);
    try {
      const plans = await readPricingPlans(customer.customerId, authToken);
      setPlans(plans)
      // Set the plan with the highest startDate as the current plan.
      setActivePlan(plans.sort((a, b) => b.startDate - a.startDate)[0]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlan();
    // eslint-disable-next-line
  }, [authToken, customer.customerId]);

  const validateInput = () => {
    if (selectedPlans.length === 0) {
      displayToast("Please select a plan to switch to.");
      return false;
    } else if (selectedPlans.length > 1) {
      displayToast("Please select only one plan to switch to.");
      return false;
    }
    if (selectedServers.length === 0) {
      displayToast("Please select a FAISS server.");
      return false;
    } else if (selectedServers.length > 1) {
      displayToast("Please select only one FAISS server.");
      return false;
    }
    // Max and min limits
    // 10 <= max corpora <=500,000
    // 100 <= max results (or rerank) <= 500
    // 50mb <= max_bytes <= 10gb

    if (scalePlanDefaults.maxCorpora < 10 || scalePlanDefaults.maxCorpora > 500000) {
      displayToast("Max corpora should be between 10 and 500,000.");
      return false;
    }
    if (scalePlanDefaults.maxResultRows < 100 || scalePlanDefaults.maxResultRows > 500) {
      displayToast("Max results should be between 100 and 500.");
      return false;
    }
    if (scalePlanDefaults.maxRerankRows < 100 || scalePlanDefaults.maxRerankRows > 500) {
      displayToast("Max rerank should be between 100 and 500.");
      return false;
    }
    if (scalePlanDefaults.maxBytes < 50 * 1024 * 1024 || scalePlanDefaults.maxBytes > 10 * 1024 * 1024 * 1024) {
      displayToast("Max bytes should be between 50MB and 10GB.");
      return false;
    }
    return true;
  };

  const switchPlan = async () => {
    if (isSwitching) return;
    if (!validateInput()) return;

    setIsSwitching(true);
    const faissServerId = selectedServers[0].id;
    const newPlanId = selectedPlans[0].planId;
    try {
      await switchCustomerPlan(customer.customerId, authToken, newPlanId, scalePlanDefaults, faissServerId, true);
      setIsPlanAccordionOpen(false);
      setIsFaissAccordionOpen(false);
      setIsOverrideDefaultsOpen(false);
      fetchPlan();
      displayToast("Plan switched successfully.");
    } catch (e) {
      console.error(e);
    } finally {
      setIsSwitching(false);
    }
  };

  const onExtendFreeTrialSuccess = () => {
    fetchPlan()
  }

  if (isLoading) {
    return (
      <VuiText>
        Loading plan, please wait ...
        <VuiSpinner size="m" />
      </VuiText>
    );
  }

  if (!activePlan) {
    return <VuiText>Could not load pricing plan.</VuiText>;
  }

  return (
    <VuiFlexContainer direction="column" className="ml-3">
      <CustomerPlanHistory activePlan={activePlan} plans={plans} onExtendFreeTrialSuccess={onExtendFreeTrialSuccess}/>
      <VuiSpacer size="l" />
      <VuiFlexContainer direction="row">
        <VuiFlexItem>
          Current Plan:
          <div className="mt-2 text-sm plan-json">
            <ReactJson
              src={JSON.parse(activePlan?.planJson || "{}")}
              displayObjectSize={false}
              displayDataTypes={false}
              name="pricing_plan"
              collapsed={false}
            />
          </div>
        </VuiFlexItem>
        <VuiFlexItem className="ml-15">
          <VuiFlexContainer justifyContent="spaceBetween" alignItems="start">
            <VuiFlexItem grow={false}>
              <VuiTitle size="m">
                <h2>Switch Plan</h2>
              </VuiTitle>
              <VuiSpacer size="s" />

              <VuiText className="switch-description">
                You can switch a plan by selecting a plan and a FAISS server. You can also override the defaults. Click
                the button to switch the plan.
              </VuiText>
              <VuiSpacer size="l" />
              <VuiButtonPrimary className="w-28" color="neutral" onClick={switchPlan}>
                {isSwitching ? <VuiSpinner size="xs" /> : "Switch Plan"}
              </VuiButtonPrimary>
            </VuiFlexItem>
          </VuiFlexContainer>

          <VuiSpacer size="l" />
          <VuiAccordion
            header="Select an available plan"
            isOpen={isPlanAccordionOpen}
            setIsOpen={setIsPlanAccordionOpen}
          >
            <AvailablePlans selected={selectedPlans} setSelected={setSelectedPlans} />
          </VuiAccordion>
          <VuiSpacer size="l" />
          <VuiAccordion
            header="Select a FAISS Server"
            isOpen={isFaissAccordionOpen}
            setIsOpen={setIsFaissAccordionOpen}
          >
            <NewPlanConfig selected={selectedServers} setSelected={setSelectedServers} />
          </VuiAccordion>
          <VuiSpacer size="l" />
          <VuiAccordion header="Plan Defaults." isOpen={isOverrideDefaultsOpen} setIsOpen={setIsOverrideDefaultsOpen}>
            <OverrideDefaults planDefaults={scalePlanDefaults} setPlanDefaults={setScalePlanDefaults} />
          </VuiAccordion>
        </VuiFlexItem>
      </VuiFlexContainer>
    </VuiFlexContainer>
  );
};
