import { Customer, CustomerStatus } from "../../utils/commonTypes";

type Props = {
  customer: Customer;
};

const getStatusString = (status: CustomerStatus) => {
  switch (status) {
    case CustomerStatus.ACT:
      return "Active";
    case CustomerStatus.MDEL:
      return "Marked as deleted";
    case CustomerStatus.DEL:
      return "Deleted";
    case CustomerStatus.EXP:
      return "Expired"
  }
};

export const CustomerHeader = ({ customer }: Props) => {
  return (
    <div>
      <div className="text-base">
        <b>Customer Details</b>
      </div>
      <div className="bg-white sm:rounded-md text-sm font-normal text-gray-800 mt-2">
        Name: <strong>{customer.userName}</strong>
        <br />
        Email: <strong>{customer.userEmail}</strong>
        <br />
        ID: <strong>{customer.customerId}</strong>
        <br />
        Status: <strong>{getStatusString(customer.status)}</strong>
      </div>
    </div>
  );
};
