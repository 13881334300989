import { useEffect, useState } from "react";
import { Plan } from "../../utils/commonTypes";
import { VuiBadge, VuiFlexContainer, VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { dateFromInstant } from "../../utils/commonFunctions";

type Props = {
  activePlan: Plan
  plans: Plan[]
}

export const CustomerPlanHistory = ({ activePlan, plans }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (plans.length) {
      setIsLoading(false)
    }
  }, [plans]);

  const columns = [
    {
      name: "status",
      header: {
        render: () => {
          return "Status"
        }
      },
      render: (plan: Plan) => {
        if (!plan) {
          return
        }
        return plan === activePlan ? <VuiBadge color="success">Active</VuiBadge> : null
      }
    },
    {
      name: "startDate",
      header: {
        render: () => {
          return "Start Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return dateFromInstant(plan.startDate).toDateString();
      }
    },
    {
      name: "planName",
      header: {
        render: () => {
          return "Plan Name";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planName;
      }
    },
    {
      name: "planId",
      header: {
        render: () => {
          return "Plan ID";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planId;
      }
    },
    {
      name: "expiryDate",
      header: {
        render: () => {
          return "Expiry Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.expiryDate ? dateFromInstant(plan.expiryDate).toDateString() : "N/A";
      }
    },
    {
      name: "planDescription",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planDescription;
      }
    }
  ];

  return (
    <VuiFlexContainer direction="column" className="plan-history-width">
      <VuiFlexContainer alignItems="center">
        <VuiText className="font-bold text-base">Plan History</VuiText>
      </VuiFlexContainer>
      {isLoading ? (
        <VuiText>
          Loading plan history, please wait ...
          <VuiSpinner size="m" />
        </VuiText>
      ) : (
        <VuiTable idField={(plan: Plan) => `${plan.planId}-${plan.startDate}:${plan.expiryDate}`} columns={columns} rows={plans} />
      )}
    </VuiFlexContainer>
  );
};
