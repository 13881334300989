import React from "react";
import { VuiFlexContainer, VuiText } from "@vectara/vectara-ui";
import { PricingPlanFeature } from "../../utils/commonTypes";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { PlanActions, getAllFeatures } from "./CreatePlanConstants";

type Props = {
  enabledFeatures: PricingPlanFeature[];
  updatePricingPlan: React.Dispatch<PlanActions>;
};

export const PlanFeature = ({ enabledFeatures, updatePricingPlan }: Props) => {
  const isIncluded = (feature: PricingPlanFeature) => {
    for (let i = 0; i < enabledFeatures.length; i++) {
      if (enabledFeatures[i].feature === feature.feature) {
        return true;
      }
    }
    return false;
  };
  const allFeatures = getAllFeatures();
  return (
    <VuiFlexContainer className="ml-10 mt-10">
      <VuiFlexContainer direction="column" className="ml-5">
        <VuiText className="font-bold mb-2">Features</VuiText>
        {allFeatures.map((feature) => (
          <FormControlLabel
            control={<Checkbox size="small" checked={isIncluded(feature)} />}
            label={<Typography sx={{ fontSize: 12 }}>{feature.feature}</Typography>}
            onChange={() => {
              const newFeatures = isIncluded(feature)
                ? enabledFeatures.filter((f) => f.feature !== feature.feature)
                : [...enabledFeatures, feature];
              updatePricingPlan({ type: "UPDATE_FIELD", field: "enabledFeatures", value: newFeatures });
            }}
          />
        ))}
      </VuiFlexContainer>
    </VuiFlexContainer>
  );
};
