import { VuiFlexContainer, VuiSpacer, VuiText } from "@vectara/vectara-ui";
import { TabbedRoutes } from "./tabbedRoutes";
import "./LandingPage.css";
import { Plans } from "./Plans";
import { ScaleCustomers } from "./reports/ScaleCustomers";
import { useLoginContext } from "../utils/LoginContext";
import { AWSMPCustomers } from "./AWSMPCustomers";
import { FailedDocs } from "./FailedDocs";
import { SearchCustomer } from "./SearchCustomer";
import { ManagementPage } from "./management";

export const LandingPage = () => {
  const { isAuthenticated, authUser } = useLoginContext();

  const DEFAULT_ROUTE = "search-customer";

  // Create base routes
  const customerTabbedRoutes = [
    {
      href: "search-customer",
      title: "Search Customer",
      component: <SearchCustomer />
    },
    {
      href: "customer-plans",
      title: "Customer Plans",
      component: <Plans />
    },
    {
      href: "reports",
      title: "Reports",
      component: <ScaleCustomers />
    },
    {
      href: "awsmp-customers",
      title: "AWS MP Customers",
      component: <AWSMPCustomers />
    },
    {
      href: "failed-uploads",
      title: "Failed Uploads",
      component: <FailedDocs />
    }
  ];

  // Add User Management tab only for users with appropriate permissions
  // Add User & Role Management tab if user has appropriate permissions
  if (authUser.permissions.includes("PERMISSION_MANAGE_USERS") || 
      authUser.permissions.includes("PERMISSION_MANAGE_ROLES")) {
    customerTabbedRoutes.push({
      href: "management",
      title: "User & Role Management",
      component: <ManagementPage />
    });
  }

  if (!isAuthenticated) {
    return (
      <VuiFlexContainer>
        <VuiSpacer size="xl" />
        <VuiText size="l" className="ml-10">
          {" "}
          Please sign in to continue.{" "}
        </VuiText>
      </VuiFlexContainer>
    );
  }

  return (
    <VuiFlexContainer
      direction="column"
      alignItems="start"
      justifyContent="start"
      spacing="l"
      className="main_container"
    >
      <TabbedRoutes routes={customerTabbedRoutes} defaultRoute={DEFAULT_ROUTE} />
    </VuiFlexContainer>
  );
};
