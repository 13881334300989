import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Plan } from "./commonTypes";
import { readAvailablePlans } from "../api/publicApi";

interface PlansContextType {
  availablePlans: Array<Plan>;
  setAvailablePlans: (availablePlans: Array<Plan>) => void;
}

const PlansContext = createContext<PlansContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const PlansContextProvider = ({ children }: Props) => {
  const [availablePlans, setAvailablePlans] = useState<Array<Plan>>([]);

  useEffect(() => {
    const fetchAvailablePlans = async () => {
      try {
        setAvailablePlans(await readAvailablePlans());
      } catch (e) {
        console.error(e);
      }
    };
    fetchAvailablePlans();
  }, []);

  return (
    <PlansContext.Provider
      value={{
        availablePlans: availablePlans,
        setAvailablePlans: setAvailablePlans
      }}
    >
      {children}
    </PlansContext.Provider>
  );
};

export const usePlansContext = () => {
  const context = useContext(PlansContext);
  if (context === undefined) {
    throw new Error("usePlansContext must be used within a PlansContextProvider");
  }
  return context;
};
