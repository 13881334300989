import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { VuiFlexContainer, VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { Encoder } from "../../utils/commonTypes";
import { useEffect, useState } from "react";
import { createEncoder, listEncoders } from "../../api/api";
import { useLoginContext } from "../../utils/LoginContext";
import { displayToast } from "../../utils/errorHandler";
import { useCustomerContext } from "../../utils/CustomerContext";

export const CustomerEncoders = () => {
  const [open, setOpen] = useState(false);

  const [dimension, setDimension] = useState(768);
  const [port, setPort] = useState(50051);
  const [name, setName] = useState("Vectara-QA-230712");
  const [description, setDescription] = useState("Vectara QA Encoder");
  const [host, setHost] = useState("qa-embedder-230712.zir-prod");
  const [encoders, setEncoders] = useState<Array<Encoder>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();

  const fetchEncoders = async () => {
    setIsLoading(true);
    try {
      setEncoders(await listEncoders(customer.customerId, authToken));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEncoders();
    // eslint-disable-next-line
  }, [authToken, customer.customerId]);

  const columns = [
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (encoder: Encoder) => {
        if (!encoder) return;
        return <>{encoder.name}</>;
      }
    },
    {
      name: "description",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (encoder: Encoder) => {
        if (!encoder) return;
        return <>{encoder.description}</>;
      }
    },
    {
      name: "dimension",
      header: {
        render: () => {
          return "Dimension";
        }
      },
      render: (encoder: Encoder) => {
        if (!encoder) return;
        return <>{encoder.dimension}</>;
      }
    },
    {
      name: "default",
      header: {
        render: () => {
          return "Default";
        }
      },
      render: (encoder: Encoder) => {
        if (!encoder) return;
        return <>{encoder.default ? "True" : "False"}</>;
      }
    },
    {
      name: "cxSpec",
      header: {
        render: () => {
          return "Connection Spec";
        }
      },
      render: (encoder: Encoder) => {
        if (!encoder) return;
        return <>{encoder.cxSpec}</>;
      }
    }
  ];

  const tableContent = !encoders?.length ? (
    <VuiText>
      <p>No encoders found.</p>
    </VuiText>
  ) : undefined;

  if (isLoading && !encoders?.length) {
    return (
      <VuiText>
        Loading encoders, please wait ...
        <VuiSpinner size="m" />
      </VuiText>
    );
  }

  if (!encoders?.length) {
    return <VuiText>No encoders found for this customer.</VuiText>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    try {
      await createEncoder(customer.customerId, authToken, name, description, dimension, host, port);
      fetchEncoders();
      displayToast("Encoder created successfully.");
    } catch (e) {
      console.error(e);
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className="rounded-t-xl overflow-hidden">
      <VuiFlexContainer className="mb-4 text-base">
        <Button variant="outlined" onClick={handleClickOpen}>
          Create new Encoder
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Create new Encoder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This encoder will be added to the customer database, and will be available for use in the system.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              id="desc"
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              id="host"
              label="Host"
              value={host}
              onChange={(e) => setHost(e.target.value)}
              fullWidth
              variant="standard"
            />
            <TextField
              sx={{ width: "20ch", mr: 5 }}
              margin="dense"
              id="port"
              label="Port"
              value={port}
              onChange={(e) => setPort(parseInt(e.target.value))}
              variant="standard"
            />
            <TextField
              sx={{ width: "20ch" }}
              margin="dense"
              id="dimension"
              value={dimension}
              onChange={(e) => setDimension(parseInt(e.target.value))}
              label="Dimensions"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleCreate}>Create</Button>
          </DialogActions>
        </Dialog>
      </VuiFlexContainer>
      <VuiTable
        fluid
        idField={(encoder: Encoder) => encoder.name.toString()}
        columns={columns}
        rows={encoders}
        content={tableContent}
      />
    </div>
  );
};
