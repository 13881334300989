import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Customer, CustomerStatus } from "./commonTypes";
import { getCustomerData, setCustomerData } from "./storage";

interface CustomerContextType {
  customer: Customer;
  setCustomer: (customer: Customer) => void;
  updateCustomer: (customer: Customer) => void;
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const CustomerContextProvider = ({ children }: Props) => {
  const [customer, setCustomer] = useState<Customer>({
    customerId: 1,
    userEmail: "abc",
    userName: "test",
    status: CustomerStatus.ACT
  });

  useEffect(() => {
    // Load the customer from localStorage
    const customerData = getCustomerData();
    if (customerData) {
      setCustomer(customerData);
    }
  }, []);

  const updateCustomer = (customer: Customer) => {
    // Save the customer state in localStorage as well.
    setCustomerData(customer);
    setCustomer(customer);
  };

  return (
    <CustomerContext.Provider value={{ customer, setCustomer, updateCustomer }}>{children}</CustomerContext.Provider>
  );
};

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error("useCustomerContext must be used within a CustomerContextProvider");
  }
  return context;
};
