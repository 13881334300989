import { useState } from "react";
import { toast } from "react-toastify";
import {
  VuiCallout,
  VuiText,
  VuiSpacer,
  VuiFlexContainer,
  VuiButtonPrimary,
  VuiIcon,
  VuiTable
} from "@vectara/vectara-ui";
import { useLoginContext } from "../../../utils/LoginContext";
import { Role, User } from "../../../utils/commonTypes";
import { AiOutlineDelete, AiOutlinePlus, AiOutlineEdit } from "react-icons/ai";
import { AddUserModal, EditUserRoleModal } from ".";
import { deleteUser } from "../../../api/api";

interface UserManagementProps {
  users: User[];
  roles: Role[];
  isLoading: boolean;
  onUserUpdated: () => Promise<void>;
}

export const UserManagement: React.FC<UserManagementProps> = ({ users, roles, isLoading, onUserUpdated }) => {
  const { authToken, authUser } = useLoginContext();
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleDeleteUser = async (userId: number) => {
    try {
      await deleteUser(authToken, userId);
      toast.success("User deleted successfully");
      onUserUpdated();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error instanceof Error ? error.message : "Failed to delete user");
    }
  };

  const openEditRoleModal = (user: User) => {
    setSelectedUser(user);
    setIsEditRoleModalOpen(true);
  };

  const columns = [
    {
      name: "username",
      header: { render: () => "UserName" },
      render: (user: User) => user?.username || ""
    },
    {
      name: "organization",
      header: { render: () => "Organization" },
      render: (user: User) => user?.organization || ""
    },
    {
      name: "description",
      header: { render: () => "Description" },
      render: (user: User) => user?.description || ""
    },
    {
      name: "role",
      header: { render: () => "Role" },
      render: (user: User) => (user?.roles && user.roles.length > 0 ? user.roles[0] : "")
    },
    {
      name: "actions",
      header: { render: () => "Actions" },
      render: (user: User) => {
        if (!user) return null;
        return (
          <VuiFlexContainer>
            <VuiButtonPrimary
              size="s"
              color="primary"
              onClick={() => openEditRoleModal(user)}
              icon={
                <VuiIcon size="s">
                  <AiOutlineEdit />
                </VuiIcon>
              }
            >
              Edit Role
            </VuiButtonPrimary>

            <VuiButtonPrimary
              size="s"
              color="danger"
              onClick={() => handleDeleteUser(user.id)}
              icon={
                <VuiIcon size="s">
                  <AiOutlineDelete />
                </VuiIcon>
              }
              isDisabled={authUser.email === user.username} // Prevent deleting own account
            >
              Delete
            </VuiButtonPrimary>
          </VuiFlexContainer>
        );
      }
    }
  ];

  const tableContent = !users?.length ? (
    <VuiText>
      <p>No users.</p>
    </VuiText>
  ) : undefined;

  return (
    <div style={{ width: "75%", margin: "0 auto" }}>
      <VuiCallout color="primary" title="Manage Users" headingElement="h2">
        <VuiFlexContainer alignItems="center" justifyContent="spaceBetween">
          <VuiText>
            Manage users and their roles. Users with the Admin role have full permissions to manage the system.
          </VuiText>
          <VuiButtonPrimary
            size="m"
            color="primary"
            onClick={() => setIsAddUserModalOpen(true)}
            icon={
              <VuiIcon size="s">
                <AiOutlinePlus />
              </VuiIcon>
            }
          >
            Add User
          </VuiButtonPrimary>
        </VuiFlexContainer>

        <VuiSpacer size="m" />

        <VuiSpacer size="l" />

        {isLoading ? (
          <VuiText>Loading users...</VuiText>
        ) : (
          <div style={{ width: "95%", margin: "0 auto" }}>
            <VuiTable
              fluid
              idField={(user: User) => user.id.toString()}
              columns={columns}
              rows={users}
              content={tableContent}
            />
          </div>
        )}
      </VuiCallout>

      {/* Modals */}
      <AddUserModal
        isOpen={isAddUserModalOpen}
        roles={roles}
        onClose={() => setIsAddUserModalOpen(false)}
        onUserAdded={onUserUpdated}
      />

      <EditUserRoleModal
        isOpen={isEditRoleModalOpen}
        user={selectedUser}
        roles={roles}
        onClose={() => setIsEditRoleModalOpen(false)}
        onUserUpdated={onUserUpdated}
      />
    </div>
  );
};

export default UserManagement;
