import { PricingPlan } from "../../utils/commonTypes";

export enum PlanItem {
  QUERY,
  INGEST,
  STORAGE,
  CORPORA,
  USERS,
  SUMMARIZATIONS,
  APIKEYS,
  BASE,
  BUNDLE
}

export type PlanActions =
  | { type: "UPDATE_FIELD"; field: string; value: string | number | PricingPlanFeature[] }
  | { type: "UPDATE_BASE_AMOUNT"; valueType: PlanItem; amount: string | number }
  | { type: "UPDATE_BUNDLE"; field: string; value: string | number }
  | { type: "UPDATE_OVERAGE_AMOUNT"; valueType: PlanItem; amount: number }
  | { type: "UPDATE_OVERAGE_PRICE"; valueType: PlanItem; price: string }
  | { type: "UPDATE_PERIOD"; valueType: PlanItem; period: string }
  | { type: "RESET" };

export enum FeatureType {
  FEATURE__UNKNOWN = 0,
  FEATURE__RECEIVE_SCORE = 1,
  FEATURE__QUERY_BG_VEC = 2,
  FEATURE__CUSTOM_DIMENSIONS = 3,
  FEATURE__ENCODER_SWAPPING = 4,
  FEATURE__TEXTLESS = 5,
  FEATURE__USER_RATE_LIMIT = 6,
  FEATURE__CORPUS_RATE_LIMIT = 7,
  FEATURE__CORPUS_ENCRYPTION_KEY = 8,
  FEATURE__CUSTOMER_MANAGED_ENCRYPTION_KEY = 9,
  FEATURE__DOCUMENT_METADATA = 10,
  FEATURE__DOCUMENT_PART_METADATA = 11,
  FEATURE__PREDICATE_FILTERING = 12
}

export type PricingPlanFeature = {
  feature: keyof typeof FeatureType;
};

export const getAllFeatures = (): PricingPlanFeature[] => {
  const features: PricingPlanFeature[] = [];
  for (const feature in FeatureType) {
    if (isNaN(Number(feature))) {
      features.push({ feature: feature as keyof typeof FeatureType });
    }
  }
  return features;
};

export const getInitialFeatures = (): PricingPlanFeature[] => {
  return [
    { feature: "FEATURE__RECEIVE_SCORE" },
    { feature: "FEATURE__CORPUS_RATE_LIMIT" },
    { feature: "FEATURE__CORPUS_ENCRYPTION_KEY" },
    { feature: "FEATURE__DOCUMENT_METADATA" },
    { feature: "FEATURE__DOCUMENT_PART_METADATA" }
  ];
};

export const initialPlan: PricingPlan = {
  enabledFeatures: getInitialFeatures(),
  includedQueries: {
    amount: 15000,
    period: {
      years: 0,
      months: 1,
      days: 0
    }
  },
  queryOverage: {
    amount: 0,
    price: {
      amount: 0,
      currency: "USD"
    }
  },
  includedIndexing: {
    amount: 524288000,
    period: {
      years: 0,
      months: 1,
      days: 0
    }
  },
  indexingOverage: {
    amount: 0,
    price: {
      amount: 0,
      currency: "USD"
    }
  },
  includedStorage: 52428800,
  storageOverage: {
    amount: 0,
    price: {
      amount: 0,
      currency: "USD"
    }
  },
  includedCorpora: 10,
  corpusOverage: {
    amount: 10,
    price: {
      amount: 0,
      currency: "USD"
    }
  },
  queryStorageIngestBundle: {
    period: {
      years: 0,
      months: 1,
      days: 0
    },
    ingestBytes: 52428800,
    queries: 1000,
    storageBytes: 5242880,
    summarizations: 1000
  },
  includedUsers: 5,
  userOverage: {
    amount: 10,
    price: {
      amount: 0,
      currency: "USD"
    }
  },
  includedApiKeys: 1000,
  includedSummarizations: {
    amount: 15000,
    period: {
      years: 0,
      months: 1,
      days: 0
    }
  },
  base: {
    amount: {
      amount: 0,
      currency: "USD"
    },
    period: {
      years: 0,
      months: 1,
      days: 0
    }
  },
  servingReplicas: 1,
  maxRerankRows: 0,
  maxResultRows: 100,
  maxFilterAttrs: 200,
  maxIndexedFilterAttrs: 100,
  maxAttrsPerFilter: 100,
  maxFilterSize: 200,
  maxFilterLength: 200
};
