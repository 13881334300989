import {
  Box,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import { VuiButtonSecondary, VuiFlexContainer, VuiFlexItem, VuiIcon, VuiText } from "@vectara/vectara-ui";
import { useReducer, useState } from "react";
import { PricingPlan, PricingPlanPeriod } from "../../utils/commonTypes";
import { PlanActions, PlanItem, initialPlan } from "./CreatePlanConstants";
import { PlanFeature } from "./ PlanFeature";
import { PlanIncludes } from "./PlanIncludes";
import { PlanBundle } from "./PlanBundle";
import { PlanOverage } from "./PlanOverage";
import { PlanJson } from "./PlanJson";
import { BiCopy } from "react-icons/bi";
import _ from "lodash";

const getApplicablePeriod = (period: string): PricingPlanPeriod => {
  return {
    days: period === "day" ? 1 : 0,
    months: period === "month" ? 1 : 0,
    years: period === "year" ? 1 : 0
  };
};

const updateMethodToTypeToPathMap = {
  baseAmount: {
    [PlanItem.QUERY]: "includedQueries.amount",
    [PlanItem.INGEST]: "includedIndexing.amount",
    [PlanItem.STORAGE]: "includedStorage",
    [PlanItem.CORPORA]: "includedCorpora",
    [PlanItem.USERS]: "includedUsers",
    [PlanItem.SUMMARIZATIONS]: "includedSummarizations.amount",
    [PlanItem.APIKEYS]: "includedApiKeys",
    [PlanItem.BASE]: "base.amount.amount"
  },
  overageAmount: {
    [PlanItem.QUERY]: "queryOverage.amount",
    [PlanItem.INGEST]: "indexingOverage.amount",
    [PlanItem.STORAGE]: "storageOverage.amount",
    [PlanItem.CORPORA]: "corpusOverage.amount",
    [PlanItem.USERS]: "userOverage.amount"
  },
  overagePrice: {
    [PlanItem.QUERY]: "queryOverage.price.amount",
    [PlanItem.INGEST]: "indexingOverage.price.amount",
    [PlanItem.STORAGE]: "storageOverage.price.amount",
    [PlanItem.CORPORA]: "corpusOverage.price.amount",
    [PlanItem.USERS]: "userOverage.price.amount"
  },
  period: {
    [PlanItem.QUERY]: "includedQueries.period",
    [PlanItem.INGEST]: "includedIndexing.period",
    [PlanItem.SUMMARIZATIONS]: "includedSummarizations.period",
    [PlanItem.BASE]: "base.period",
    [PlanItem.BUNDLE]: "queryStorageIngestBundle.period"
  }
} as const;

type UpdateMethod = keyof typeof updateMethodToTypeToPathMap;

const update = (
  pricingPlan: PricingPlan,
  updateMethod: UpdateMethod,
  type: PlanItem,
  amount: string | number | PricingPlanPeriod
) => {
  const path = _.get(updateMethodToTypeToPathMap, [updateMethod, type]);

  if (!path) {
    return pricingPlan;
  }

  return _.set(_.cloneDeep(pricingPlan), path, amount);
};

const reducer = (pricingPlan: PricingPlan, action: PlanActions): PricingPlan => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...pricingPlan, [action.field]: action.value };
    case "UPDATE_BASE_AMOUNT":
      return update(pricingPlan, "baseAmount", action.valueType, Number(action.amount));
    case "UPDATE_BUNDLE":
      return {
        ...pricingPlan,
        queryStorageIngestBundle: { ...pricingPlan.queryStorageIngestBundle, [action.field]: Number(action.value) }
      };
    case "UPDATE_OVERAGE_AMOUNT":
      return update(pricingPlan, "overageAmount", action.valueType, Number(action.amount));
    case "UPDATE_OVERAGE_PRICE":
      return update(pricingPlan, "overagePrice", action.valueType, Number(action.price));
    case "UPDATE_PERIOD":
      return update(pricingPlan, "period", action.valueType, getApplicablePeriod(action.period));
    case "RESET":
      return initialPlan;
    default:
      return pricingPlan;
  }
};

const getId = (maxLength: number): string => {
  if (maxLength <= 0) {
    throw new Error("Length must be greater than zero.");
  }

  const validChars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let id = "";
  for (let i = 0; i < maxLength; i++) {
    const randomIndex = Math.floor(Math.random() * validChars.length);
    id += validChars.charAt(randomIndex);
  }
  return id;
};

export const CreatePlan = () => {
  const [planName, setPlanName] = useState<string>("");
  const [planDesc, setPlanDesc] = useState<string>("");
  const [corporaPerQuery, setCorporaPerQuery] = useState<number>(10);
  const [initialCreditAmount, setInitialCreditAmount] = useState<number>(0);
  const [maxUses, setMaxUses] = useState<number>(0);
  const [isBundlePlan, setIsBundlePlan] = useState<boolean>(true);

  const [pricingPlan, updatePricingPlan] = useReducer(reducer, initialPlan);

  const getPricingPlanSql = () => {
    return `INSERT INTO pricing_plan (plan, comment, tx_create) VALUES ('${JSON.stringify(
      pricingPlan
    )}', 'Generated by C3', UNIX_TIMESTAMP());`;
  };

  const getCustomerPlanSql = () => {
    const planId = getId(20);
    return `INSERT INTO customer_plan (id, name, description, customer_template_id, pricing_plan_id, 
      max_corpora_per_query, credit, currency, enabled, uses, max_uses, tx_create) 
      VALUES ('${planId}', '${planName}', '${planDesc}', 'DEFAULT', $ADD_PLAN_ID_HERE, 
      ${corporaPerQuery}, ${initialCreditAmount}, 'USD', true, 0, ${maxUses}, UNIX_TIMESTAMP());`;
  };

  return (
    <VuiFlexContainer>
      <VuiFlexItem>
        <VuiFlexContainer direction="column" className="create-plan">
          <VuiText className="font-bold ml-2">Plan Basics</VuiText>
          <Box component="form" sx={{ fontSize: 12 }}>
            <FormControl sx={{ width: "35ch", m: 1 }}>
              <InputLabel htmlFor="plan-name">Plan Name</InputLabel>
              <Input
                id="plan-name"
                error={planName === ""}
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: "62ch", m: 1 }}>
              <InputLabel htmlFor="plan-desc">Plan Description</InputLabel>
              <Input
                id="plan-desc"
                error={planDesc === ""}
                value={planDesc}
                onChange={(e) => setPlanDesc(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="corpora-per-query">Max corpora per query</InputLabel>
              <Input
                id="corpora-per-query"
                value={corporaPerQuery}
                onChange={(e) => setCorporaPerQuery(Number(e.target.value))}
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-uses">Max plan uses</InputLabel>
              <Input id="max-uses" value={maxUses} onChange={(e) => setMaxUses(Number(e.target.value))} />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="initial-credit">Initial Credit Amount</InputLabel>
              <Input
                id="initial-amount"
                value={initialCreditAmount}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={(e) => setInitialCreditAmount(Number(e.target.value))}
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-api-keys">Max API Keys</InputLabel>
              <Input
                id="max-api-keys"
                value={pricingPlan.includedApiKeys}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "includedApiKeys", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-result-rows">Max Result Rows</InputLabel>
              <Input
                id="max-result-rows"
                value={pricingPlan.maxResultRows}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxResultRows", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-rerank-rows">Max Rerank Rows</InputLabel>
              <Input
                id="max-rerank-rows"
                value={pricingPlan.maxRerankRows}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxRerankRows", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-filter-attrs">Max Filter Attributes</InputLabel>
              <Input
                id="max-filter-attrs"
                value={pricingPlan.maxFilterAttrs}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxFilterAttrs", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-indexed-filter-attrs">Max Indexed Fitler Attributes</InputLabel>
              <Input
                id="max-indexed-filter-attrs"
                value={pricingPlan.maxIndexedFilterAttrs}
                onChange={(e) =>
                  updatePricingPlan({
                    type: "UPDATE_FIELD",
                    field: "maxIndexedFilterAttrs",
                    value: Number(e.target.value)
                  })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-attrs-per-filter">Max Attributes Per Filter</InputLabel>
              <Input
                id="max-attrs-per-filter"
                value={pricingPlan.maxAttrsPerFilter}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxAttrsPerFilter", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-filter-size">Max Filter Size</InputLabel>
              <Input
                id="max-filter-size"
                value={pricingPlan.maxFilterSize}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxFilterSize", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="max-filter-length">Max Filter Length</InputLabel>
              <Input
                id="max-filter-length"
                value={pricingPlan.maxFilterLength}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "maxFilterLength", value: Number(e.target.value) })
                }
              />
            </FormControl>
            <FormControl sx={{ width: "18ch", m: 1 }}>
              <InputLabel htmlFor="serving-replicas">Serving Replicas</InputLabel>
              <Input
                id="serving-replicas"
                value={pricingPlan.servingReplicas}
                onChange={(e) =>
                  updatePricingPlan({ type: "UPDATE_FIELD", field: "servingReplicas", value: Number(e.target.value) })
                }
              />
            </FormControl>
          </Box>

          <VuiFlexContainer direction="row">
            <PlanIncludes pricingPlan={pricingPlan} updatePricingPlan={updatePricingPlan} />
            <PlanFeature enabledFeatures={pricingPlan.enabledFeatures} updatePricingPlan={updatePricingPlan} />
          </VuiFlexContainer>

          <RadioGroup
            defaultValue="bundle"
            className="mt-10"
            name="plan-type"
            row
            onChange={(e) => setIsBundlePlan(e.target.value === "bundle")}
          >
            <FormControlLabel value="bundle" control={<Radio />} label="Bundle" />
            <FormControlLabel value="overage" control={<Radio />} label="Non-bundle" />
          </RadioGroup>
          {isBundlePlan ? (
            <PlanBundle pricingPlan={pricingPlan} updatePricingPlan={updatePricingPlan} />
          ) : (
            <PlanOverage pricingPlan={pricingPlan} updatePricingPlan={updatePricingPlan} />
          )}
        </VuiFlexContainer>
      </VuiFlexItem>
      <VuiFlexItem>
        <VuiFlexContainer direction="column" className="plan-json">
          <VuiFlexContainer className="mt-5 mb-5 ml-3" justifyContent="spaceBetween">
            <VuiButtonSecondary
              color="primary"
              size="xs"
              icon={
                <VuiIcon size="m">
                  <BiCopy />
                </VuiIcon>
              }
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(pricingPlan));
              }}
            >
              Plan JSON
            </VuiButtonSecondary>
            <VuiButtonSecondary
              color="primary"
              size="xs"
              icon={
                <VuiIcon size="m">
                  <BiCopy />
                </VuiIcon>
              }
              onClick={() => {
                navigator.clipboard.writeText(getPricingPlanSql());
              }}
            >
              PricingPlan SQL
            </VuiButtonSecondary>
            <VuiButtonSecondary
              color="primary"
              size="xs"
              icon={
                <VuiIcon size="m">
                  <BiCopy />
                </VuiIcon>
              }
              onClick={() => {
                navigator.clipboard.writeText(getCustomerPlanSql());
              }}
            >
              CustomerPlan SQL
            </VuiButtonSecondary>
          </VuiFlexContainer>
          <PlanJson pricingPlan={pricingPlan} />
        </VuiFlexContainer>
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};
