import { useState } from "react";
import { VuiCallout, VuiText, VuiSpacer, VuiButtonPrimary, VuiIcon, VuiTable } from "@vectara/vectara-ui";
import { Role } from "../../../utils/commonTypes";
import { AiOutlineEdit } from "react-icons/ai";
import { ManagePermissionsModal } from ".";

interface RoleManagementProps {
  roles: Role[];
  isLoading: boolean;
  onRoleUpdated: () => Promise<void>;
}

export const RoleManagement: React.FC<RoleManagementProps> = ({ roles, isLoading, onRoleUpdated }) => {
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);

  const openManagePermissionsModal = (role: Role) => {
    setSelectedRole(role);
    setIsManagePermissionsModalOpen(true);
  };

  return (
    <div style={{ width: "75%", margin: "0 auto" }}>
      <VuiCallout color="success" title="Role Permissions" headingElement="h2">
        <VuiText>Manage role-based permissions. Assign specific permissions to roles to control user access.</VuiText>

        <VuiSpacer size="l" />

        {isLoading ? (
          <VuiText>Loading roles...</VuiText>
        ) : (
          <div style={{ width: "95%", margin: "0 auto" }}>
            <VuiTable
              fluid
              idField={(role: Role) => role.id.toString()}
              columns={[
                {
                  name: "name",
                  header: { render: () => "Role Name" },
                  render: (role: Role) => role?.name || ""
                },
                {
                  name: "description",
                  header: { render: () => "Description" },
                  render: (role: Role) => role?.description || ""
                },
                {
                  name: "permissions",
                  header: { render: () => "Permissions" },
                  render: (role: Role) => (role?.permissions || []).join(", ")
                },
                {
                  name: "actions",
                  header: { render: () => "Actions" },
                  render: (role: Role) => (
                    <VuiButtonPrimary
                      size="s"
                      color="primary"
                      onClick={() => openManagePermissionsModal(role)}
                      icon={
                        <VuiIcon size="s">
                          <AiOutlineEdit />
                        </VuiIcon>
                      }
                    >
                      Edit Permissions
                    </VuiButtonPrimary>
                  )
                }
              ]}
              rows={roles}
              content={
                !roles?.length ? (
                  <VuiText>
                    <p>No roles found.</p>
                  </VuiText>
                ) : undefined
              }
            />
          </div>
        )}
      </VuiCallout>

      {/* Role Permissions Modal */}
      <ManagePermissionsModal
        isOpen={isManagePermissionsModalOpen}
        role={selectedRole}
        onClose={() => setIsManagePermissionsModalOpen(false)}
        onRoleUpdated={onRoleUpdated}
      />
    </div>
  );
};

export default RoleManagement;
