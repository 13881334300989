import ReactJson from "react-json-view";
import { VuiFlexItem } from "@vectara/vectara-ui";
import { PricingPlan } from "../../utils/commonTypes";

type Props = {
  pricingPlan: PricingPlan;
};

export const PlanJson = ({ pricingPlan }: Props) => {
  return (
    <VuiFlexItem>
      <ReactJson
        src={JSON.parse(JSON.stringify(pricingPlan) || "{}")}
        displayObjectSize={false}
        displayDataTypes={false}
        name="pricing_plan"
        collapsed={false}
      />
    </VuiFlexItem>
  );
};
