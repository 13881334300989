import { useState } from "react";
import { FailedDoc } from "../utils/commonTypes";
import { useLoginContext } from "../utils/LoginContext";
import {
  VuiButtonSecondary,
  VuiCopyButton,
  VuiFlexContainer,
  VuiIcon,
  VuiSpacer,
  VuiSpinner,
  VuiTable,
  VuiText,
  VuiTextInput
} from "@vectara/vectara-ui";
import { downloadFailedDoc, listFailedDocs } from "../api/api";
import { Backdrop, Tooltip, Typography } from "@mui/material";
import { BiDownload } from "react-icons/bi";

export const FailedDocs = () => {
  const [failedDocs, setFailedDocs] = useState<Array<FailedDoc>>([]);
  const [isSearchingByCustomerId, setIsSearchingByCustomerId] = useState<boolean>(false);
  const [isSearchingByDocId, setIsSearchingByDocId] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [customerIdToSearch, setCustomerIdToSearch] = useState<number>(0);
  const [documentId, setDocumentId] = useState<string>("");
  const { authToken, isAuthenticated } = useLoginContext();

  const fetchFailedDocs = async (customerId: number, docId: string, noOfDocs: number) => {
    if (isSearchingByCustomerId) return;
    if (isSearchingByDocId) return;
    setFailedDocs([]);
    try {
      if (authToken && isAuthenticated) {
        const failedDocs = await listFailedDocs(authToken, customerId, noOfDocs, docId);
        setFailedDocs(failedDocs);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSearchingByCustomerId(false);
      setIsSearchingByDocId(false);
    }
  };

  const parseStatusText = (text: string): string => {
    // Find status_detail: in the text and return the rest of the string
    const textToFind = "status_detail:";
    const statusDetailIndex = text.indexOf(textToFind);
    if (statusDetailIndex === -1) {
      return text;
    }
    return text.substring(statusDetailIndex + textToFind.length);
  };

  const downloadDoc = (doc: FailedDoc) => async () => {
    if (isDownloading) return;
    try {
      setIsDownloading(true);
      await downloadFailedDoc(authToken, doc);
    } catch (e) {
      console.error(e);
    } finally {
      setIsDownloading(false);
    }
  };

  const columns = [
    {
      name: "customerId",
      header: {
        render: () => {
          return "CustomerID";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return <VuiFlexContainer className="col-docs-customerid">{doc.customerId}</VuiFlexContainer>;
      }
    },
    {
      name: "corpusId",
      header: {
        render: () => {
          return "CorpusID";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return <VuiFlexContainer className="col-docs-corpusid">{doc.corpusId}</VuiFlexContainer>;
      }
    },
    {
      name: "documentId",
      header: {
        render: () => {
          return "Document";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return (
          <VuiFlexContainer className="col-docs-document">
            <Tooltip title={doc.documentId}>
              <Typography noWrap>{doc.documentId}</Typography>
            </Tooltip>
            <VuiCopyButton size="xs" value={doc.documentId} />
          </VuiFlexContainer>
        );
      }
    },
    {
      name: "type",
      header: {
        render: () => {
          return "Type";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return <VuiFlexContainer className="col-docs-type">{doc.mimeType}</VuiFlexContainer>;
      }
    },
    {
      name: "api",
      header: {
        render: () => {
          return "API";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return <VuiFlexContainer className="col-docs-codeprompt">{doc.api}</VuiFlexContainer>;
      }
    },
    {
      name: "error",
      header: {
        render: () => {
          return "Error Message";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return (
          <VuiFlexContainer className="col-docs-message">
            <Tooltip title={doc.originalStatusText}>
              <Typography noWrap>{parseStatusText(doc.originalStatusText)}</Typography>
            </Tooltip>
            <VuiCopyButton size="xs" value={doc.originalStatusText} />
          </VuiFlexContainer>
        );
      }
    },
    {
      name: "download",
      header: {
        render: () => {
          return "Download";
        }
      },
      render: (doc: FailedDoc) => {
        if (!doc) return;
        return (
          <VuiButtonSecondary
            className="col-docs-action"
            color="accent"
            size="xs"
            solid={true}
            icon={
              <VuiIcon size="m">
                <BiDownload />
              </VuiIcon>
            }
            onClick={downloadDoc(doc)}
          />
        );
      }
    }
  ];

  const tableContent = !failedDocs?.length ? (
    <VuiText>
      <p>No failed documents found.</p>
    </VuiText>
  ) : undefined;

  const searchByDocId = () => {
    setIsSearchingByDocId(true);
    fetchFailedDocs(0, documentId.trim(), 0);
  };

  const searchByCustomerId = () => {
    setIsSearchingByCustomerId(true);
    fetchFailedDocs(customerIdToSearch, "", 100);
  };

  return (
    <div className="pt-5 pl-10">
      <VuiText className="switch-description">
        Search either by a customer Id or a document Id to find failed documents.
      </VuiText>
      <VuiSpacer size="l" />
      <VuiFlexContainer>
        <VuiTextInput
          placeholder="Customer Id"
          onSubmit={searchByCustomerId}
          onChange={(e) => setCustomerIdToSearch(parseInt(e.target.value))}
        />
        <VuiButtonSecondary onClick={searchByCustomerId} color={"accent"}>
          {isSearchingByCustomerId ? <VuiSpinner size="xs" /> : "Search By Customer Id"}
        </VuiButtonSecondary>
      </VuiFlexContainer>
      <VuiSpacer size="m" />
      <VuiFlexContainer>
        <VuiTextInput
          placeholder="Document Id"
          onSubmit={searchByDocId}
          onChange={(e) => setDocumentId(e.target.value)}
        />
        <VuiButtonSecondary onClick={searchByDocId} color={"accent"}>
          {isSearchingByDocId ? <VuiSpinner size="xs" /> : "Search By Document Id"}
        </VuiButtonSecondary>
      </VuiFlexContainer>
      <VuiSpacer size="l" />

      <VuiSpacer size="l" />
      <VuiTable
        fluid
        idField={(doc: FailedDoc) => doc.documentId.toString()}
        columns={columns}
        rows={failedDocs}
        content={tableContent}
      />

      <Backdrop open={isDownloading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <VuiSpinner size="l" />
      </Backdrop>
    </div>
  );
};
