import { useCustomerContext } from "../../utils/CustomerContext";
import "./Customer.css";
import { CustomerCorpora } from "./CustomerCorpora";
import { CustomerHeader } from "./CustomerHeader";
import { CustomerJobs } from "./CustomerJobs";
import { CustomerPlan } from "./CustomerPlan";
import { TabbedRoutes } from "../tabbedRoutes";
import { CustomerStats } from "./CustomerStats";
import { VuiSpacer } from "@vectara/vectara-ui";
import { CustomerEncoders } from "./CustomerEncoders";
import { CustomerPrompts } from "./CustomerPrompts";

export const Customer = () => {
  const { customer } = useCustomerContext();

  const DEFAULT_TAB_ADDRESS = `/customer/${customer.customerId}/corpora`;

  const customerTabbedRoutes = [
    {
      href: "corpora",
      title: "Corpora",
      component: <CustomerCorpora />
    },
    {
      href: "stats",
      title: "Stats",
      component: <CustomerStats />
    },
    {
      href: "plan",
      title: "Pricing Plan",
      component: <CustomerPlan />
    },
    {
      href: "jobs",
      title: "Jobs",
      component: <CustomerJobs />
    },
    {
      href: "encoders",
      title: "Encoders",
      component: <CustomerEncoders />
    },
    {
      href: "prompts",
      title: "Prompts",
      component: <CustomerPrompts />
    }
  ];

  return (
    <div className="flex flex-col ml-10 mt-5">
      <CustomerHeader customer={customer} />
      <VuiSpacer size="l" />
      <TabbedRoutes routes={customerTabbedRoutes} defaultRoute={DEFAULT_TAB_ADDRESS} />
    </div>
  );
};
