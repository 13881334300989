import { useEffect, useState } from "react";
import { Plan, PlanType } from "../../utils/commonTypes";
import { VuiBadge, VuiButtonPrimary, VuiFlexContainer, VuiModal, VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { dateFromInstant } from "../../utils/commonFunctions";
import { CustomerPlanExtendFreeTrial } from "./CustomerPlanExtendFreeTrial";

type Props = {
  activePlan: Plan
  plans: Plan[]
  onExtendFreeTrialSuccess: () => void
}

export const CustomerPlanHistory = ({ activePlan, plans, onExtendFreeTrialSuccess }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isExtendFreeTrialModalOpen, setIsExtendFreeTrialModelOpen] = useState(false)

  useEffect(() => {
    if (plans.length) {
      setIsLoading(false)
    }
  }, [plans]);

  const columns = [
    {
      name: "status",
      header: {
        render: () => {
          return "Status"
        }
      },
      render: (plan: Plan) => {
        if (!plan) {
          return
        }
        return plan === activePlan ? <VuiBadge color="success">Active</VuiBadge> : null
      }
    },
    {
      name: "startDate",
      header: {
        render: () => {
          return "Start Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return dateFromInstant(plan.startDate).toDateString();
      }
    },
    {
      name: "planName",
      header: {
        render: () => {
          return "Plan Name";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planName;
      }
    },
    {
      name: "planId",
      header: {
        render: () => {
          return "Plan ID";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planId;
      }
    },
    {
      name: "expiryDate",
      header: {
        render: () => {
          return "Expiry Date";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.expiryDate ? dateFromInstant(plan.expiryDate).toDateString() : "N/A";
      }
    },
    {
      name: "planDescription",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planDescription;
      }
    }
  ];

  return (
    <VuiFlexContainer direction="column" className="plan-history-width">
      <VuiFlexContainer alignItems="center">
        <VuiText className="font-bold text-base">Plan History</VuiText>
        {activePlan.planType === PlanType.PLAN_NAME__FREE_TRIAL ? 
          <VuiButtonPrimary className="text-sm" color="neutral" onClick={() => setIsExtendFreeTrialModelOpen(true)}>
          Extend
        </VuiButtonPrimary> : null}
      </VuiFlexContainer>
      <VuiModal
        color="primary"
        isOpen={isExtendFreeTrialModalOpen}
        onClose={() => setIsExtendFreeTrialModelOpen(false)}
        title="Extend Free Trial"
      >
        <CustomerPlanExtendFreeTrial activePlan={activePlan} onSuccess={() => {
          setIsExtendFreeTrialModelOpen(false);
          onExtendFreeTrialSuccess()
        }} />
      </VuiModal>
      {isLoading ? (
        <VuiText>
          Loading plan history, please wait ...
          <VuiSpinner size="m" />
        </VuiText>
      ) : (
        <VuiTable idField={(plan: Plan) => `${plan.planId}-${plan.startDate}:${plan.expiryDate}`} columns={columns} rows={plans} />
      )}
    </VuiFlexContainer>
  );
};
