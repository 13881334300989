import { VuiFlexContainer, VuiFormGroup, VuiSpacer, VuiText, VuiTextInput } from "@vectara/vectara-ui";
import { ScalePlanDefaults } from "../../../utils/commonTypes";

type Props = {
  planDefaults: ScalePlanDefaults;
  setPlanDefaults: (planDefaults: ScalePlanDefaults) => void;
};

export const OverrideDefaults = ({ planDefaults, setPlanDefaults }: Props) => {
  return (
    <VuiFlexContainer direction="column">
      <VuiSpacer size="l" />
      <VuiText>
        <h3>Override Defaults</h3>
      </VuiText>
      <VuiSpacer size="s" />
      <VuiFormGroup label="Max Corpora" labelFor="maxCorpora">
        <VuiTextInput
          id="maxCorpora"
          value={planDefaults.maxCorpora.toString() ?? ""}
          onChange={(e) => setPlanDefaults({ ...planDefaults, maxCorpora: parseInt(e.target.value) })}
        />
      </VuiFormGroup>
      <VuiSpacer size="s" />
      <VuiFormGroup label="Max Result Rows" labelFor="maxResultRows">
        <VuiTextInput
          id="maxResultRows"
          value={planDefaults.maxResultRows.toString() ?? ""}
          onChange={(e) => setPlanDefaults({ ...planDefaults, maxResultRows: parseInt(e.target.value) })}
        />
      </VuiFormGroup>
      <VuiSpacer size="s" />
      <VuiFormGroup label="Max Rerank Rows" labelFor="maxRerankRows">
        <VuiTextInput
          id="maxRerankRows"
          value={planDefaults.maxRerankRows.toString() ?? ""}
          onChange={(e) => setPlanDefaults({ ...planDefaults, maxRerankRows: parseInt(e.target.value) })}
        />
      </VuiFormGroup>
      <VuiSpacer size="s" />
      <VuiFormGroup label="Data Storage (MBs)" labelFor="maxBytes">
        <VuiTextInput
          id="maxBytes"
          value={(planDefaults.maxBytes / 1024 / 1024).toString() ?? ""}
          onChange={(e) => setPlanDefaults({ ...planDefaults, maxBytes: parseInt(e.target.value) * 1024 * 1024 })}
        />
      </VuiFormGroup>
    </VuiFlexContainer>
  );
};
