import { Plan } from "../utils/commonTypes";
import { handleErrors } from "../utils/errorHandler";

const API_BACKEND = `${process.env.REACT_APP_API_ENDPOINT}`;

export const readAvailablePlans = async (): Promise<Array<Plan>> => {
  const response = await fetch(`${API_BACKEND}/read-available-plans`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({})
  });
  if (response.status !== 200) {
    handleErrors(response.status, response.statusText);
    throw new Error(response.statusText);
  }

  const jsonData = await response.json();
  const plans = jsonData.plans.map((item: Record<string, unknown>) => {
    const itemObj = JSON.parse(item.toString());
    return {
      planId: itemObj.planId,
      planName: itemObj.planName,
      planDescription: itemObj.description
    };
  });
  return plans;
};
