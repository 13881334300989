import { useEffect, useState } from "react";
import { AWSMPCustomer } from "../utils/commonTypes";
import { useLoginContext } from "../utils/LoginContext";
import { listAWSMPCustomers } from "../api/api";
import { VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";

export const AWSMPCustomers = () => {
  const [activeCustomers, setActiveCustomers] = useState<Array<AWSMPCustomer>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { authToken, isAuthenticated } = useLoginContext();

  const fetchAWSMPCustomers = async () => {
    try {
      if (authToken && isAuthenticated) {
        const awsMpCustomers = await listAWSMPCustomers(authToken);
        setActiveCustomers(awsMpCustomers);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(
    () => {
      setIsLoading(true);
      fetchAWSMPCustomers();
    },
    [authToken] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (isLoading && !activeCustomers?.length) {
    return (
      <div className="rounded-t-xl overflow-hidden pt-5 pl-10">
        <p>
          <i>Loading AWS MP customers, please wait ...</i>
        </p>
        <VuiSpinner size="m"></VuiSpinner>
      </div>
    );
  }

  const columns = [
    {
      name: "id",
      header: {
        render: () => {
          return "Id";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.customerId}</>;
      }
    },
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.name}</>;
      }
    },
    {
      name: "email",
      header: {
        render: () => {
          return "Email";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.userEmail}</>;
      }
    },
    {
      name: "awsId",
      header: {
        render: () => {
          return "AWS Id";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.awsId}</>;
      }
    },
    {
      name: "awsAccountId",
      header: {
        render: () => {
          return "AWS Account Id";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.awsAccountId}</>;
      }
    },
    {
      name: "planId",
      header: {
        render: () => {
          return "Plan Id";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.planId}</>;
      }
    },
    {
      name: "planName",
      header: {
        render: () => {
          return "Plan Name";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{customer.planName}</>;
      }
    },
    {
      name: "contractStart",
      header: {
        render: () => {
          return "Contract Start";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{new Date(customer.tsContractStart).toDateString()}</>;
      }
    },
    {
      name: "contractExpire",
      header: {
        render: () => {
          return "Contract Expire";
        }
      },
      render: (customer: AWSMPCustomer) => {
        if (!customer) return;
        return <>{new Date(customer.tsContractExpire).toDateString()}</>;
      }
    }
  ];

  const tableContent = !activeCustomers?.length ? (
    <VuiText>
      <p>No customers.</p>
    </VuiText>
  ) : undefined;

  return (
    <div>
      <VuiTable
        fluid
        idField={(customer: AWSMPCustomer) => customer.customerId.toString()}
        columns={columns}
        rows={activeCustomers}
        content={tableContent}
      />{" "}
    </div>
  );
};
