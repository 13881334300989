import {
  VuiAccordion,
  VuiButtonPrimary,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpacer,
  VuiSpinner,
  VuiText,
  VuiTextColor,
  VuiTitle
} from "@vectara/vectara-ui";
import { FreeTrialPlanParameters, Plan, PlanParameters, PlanType, ScalePlanParameters } from "../../utils/commonTypes";
import ReactJson from "react-json-view";
import { useEffect, useState } from "react";
import { displayToast } from "../../utils/errorHandler";
import { readPricingPlanDetails, readPricingPlans, updateCustomerPlan } from "../../api/api";
import { useLoginContext } from "../../utils/LoginContext";
import { ScaleParameters } from "./updatePlan/ScaleParameters";
import { AvailablePlans } from "./updatePlan/AvailablePlans";
import { useCustomerContext } from "../../utils/CustomerContext";
import { CustomerPlanHistory } from "./CustomerPlanHistory";
import { FreeTrialParameters } from "./updatePlan/FreeTrialParameters";
import dayjs from "dayjs";

export const CustomerPlan = () => {
  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isParametersAccordionOpen, setIsParametersAccordionOpen] = useState<boolean>(false);
  const [isPlanAccordionOpen, setIsPlanAccordionOpen] = useState<boolean>(false);
  const [isPlanSpecAccordionOpen, setIsPlanSpecAccordionOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [plans, setPlans] = useState<Plan[]>([])
  const [activePlan, setActivePlan] = useState<Plan>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [planParameters, setPlanParameters] = useState<PlanParameters>();
  const [planSpec, setPlanSpec] = useState<any>();
  const [isPlanSpecLoading, setIsPlanSpecLoading] = useState(false);

  const fetchPlan = async () => {
    setIsLoading(true);
    try {
      const plans = await readPricingPlans(customer.customerId, authToken);
      setPlans(plans)

      // Set the plan with the highest startDate that is less than or equal to current date as the active plan.
      const activePlan = plans
        .filter(plan => dayjs.unix(plan.startDate).isBefore(dayjs()))
        .sort((a, b) => b.startDate - a.startDate)[0];

      setActivePlan(activePlan);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlan();
    // eslint-disable-next-line
  }, [authToken, customer.customerId]);

  useEffect(() => {
    const fetchPlanDetails = async () => {
      if (!selectedPlan) return;

      setIsPlanSpecLoading(true);

      const selectedPlanIsSameAsActive = selectedPlan.planId === activePlan?.planId;
      const selectedPlanIsFreeTrial = selectedPlan.planType === PlanType.PLAN_NAME__FREE_TRIAL;

      let customerId;
      /**
       * When customerId is set, the api returns the current plan details as opposed to the plan specified by planId. 
       * We want the current plan when the selected plan is the same as the active plan or when the selected plan is a free trial.
       */
      if(selectedPlanIsSameAsActive || selectedPlanIsFreeTrial) {
        customerId = customer.customerId;
      }

      const planDetails = await readPricingPlanDetails(authToken, selectedPlan.planId, customerId);

      setIsPlanSpecLoading(false);
      setPlanSpec(planDetails.planSpec);

      // Set default values to avoid null checks
      if(selectedPlanIsFreeTrial) {
        // Set the expiry date to the current date + 30 days if the expiry date is not set.
        const expiry = activePlan?.expiryDate ? dayjs.unix(activePlan.expiryDate) : dayjs().add(30, 'day');
        setPlanParameters({ expiryEpochSecond: expiry.unix() } as FreeTrialPlanParameters)
      } else {
        setPlanParameters({ effectiveFromEpochSecond: dayjs().unix(), tableExtractionEnabled: customer.tableExtractionEnabled } as ScalePlanParameters);
      }
    };

    fetchPlanDetails();
  }, [selectedPlan]);

  const updatePlan = async () => {
    if (isUpdating) return;

    if (!selectedPlan) {
      displayToast("Please select a plan to switch to.");
      return;
    }

    if (!planParameters) {
      displayToast("Please set parameters for the selected plan.");
      return;
    }

    if(!planSpec) {
      displayToast("Please set plan spec for the selected plan.");
      return;
    }

    setIsUpdating(true);
    try {
      await updateCustomerPlan(authToken, customer.customerId, selectedPlan.planId, planSpec, planParameters);
      setIsPlanAccordionOpen(false);
      setIsParametersAccordionOpen(false);
      setIsPlanSpecAccordionOpen(false);
      fetchPlan();
      displayToast("Plan updated successfully.");
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const renderPlanParameters = () => { 
    if(!selectedPlan || !activePlan || !planParameters) {
      return <VuiText>Select a plan to view parameters</VuiText>
    }

    switch(selectedPlan?.planType) {
      case PlanType.PLAN_NAME__SCALE:
        return <ScaleParameters parameters={planParameters as ScalePlanParameters} setParameters={setPlanParameters} />;
      case PlanType.PLAN_NAME__FREE_TRIAL:
        return <FreeTrialParameters parameters={planParameters as FreeTrialPlanParameters} setParameters={setPlanParameters} />;
      default:
        return <VuiText>Select a plan to view parameters</VuiText>
    }
  }

  const renderPlanSpec = () => {
    if(!selectedPlan) {
      return <VuiText>Select a plan to view spec</VuiText>;
    }

    if(isPlanSpecLoading) {
      return (
        <VuiText>
          Loading plan spec, please wait ...
          <VuiSpinner size="m" />
        </VuiText>
      );
    }

    const isDowngradeToFreeTrial = selectedPlan.planType === PlanType.PLAN_NAME__FREE_TRIAL && activePlan?.planType !== PlanType.PLAN_NAME__FREE_TRIAL;

    return <VuiFlexContainer direction="column">
      {isDowngradeToFreeTrial && 
        <div><VuiText className="switch-description">
          <VuiTextColor color="accent">
            Note: When downgrading to free trial, storage is overriden by the system based on the last recorded consumption + 512 MB buffer to avoid immediate expiry due to quota. Buffer is added as the last recorded value may not be up to date.
          </VuiTextColor>
        </VuiText>
        <VuiSpacer size="m"/>
      </div>}
      <ReactJson
        src={planSpec}
        onEdit={edit => setPlanSpec(edit.updated_src)}
        quotesOnKeys={false}
        enableClipboard={false}
        displayDataTypes={false}
      />
    </VuiFlexContainer>
  }

  if (isLoading) {
    return (
      <VuiText>
        Loading plan, please wait ...
        <VuiSpinner size="m" />
      </VuiText>
    );
  }

  if (!activePlan) {
    return <VuiText>Could not load pricing plan.</VuiText>;
  }

  return (
    <VuiFlexContainer direction="column" className="ml-3">
      <CustomerPlanHistory activePlan={activePlan} plans={plans}/>
      <VuiSpacer size="l" />
      <VuiFlexContainer direction="row">
        <VuiFlexItem>
          Current Plan:
          <div className="mt-2 text-sm plan-json">
            <ReactJson
              src={JSON.parse(activePlan?.planJson || "{}")}
              displayObjectSize={false}
              displayDataTypes={false}
              name="pricing_plan"
              collapsed={false}
            />
          </div>
        </VuiFlexItem>
        <VuiFlexItem className="ml-15">
          <VuiFlexContainer direction="column" justifyContent="spaceBetween" alignItems="start">
            <VuiFlexItem grow={false}>
              <VuiTitle size="l">
                <h2>Update Plan</h2>
              </VuiTitle>
              <VuiSpacer size="s" />

              <VuiText className="update-description">
                Update the spec of the current plan, extend free trial or switch to a new plan.
              </VuiText>
            </VuiFlexItem>
            <VuiFlexItem>
              <VuiAccordion
                header="Select an available plan"
                isOpen={isPlanAccordionOpen}
                setIsOpen={setIsPlanAccordionOpen}
              >
                <AvailablePlans selected={selectedPlan} setSelected={setSelectedPlan} />
              </VuiAccordion>
              <VuiSpacer size="m"/>
              <VuiAccordion
                header="Set parameters for the selected plan"
                isOpen={isParametersAccordionOpen}
                setIsOpen={setIsParametersAccordionOpen}
              >
                {renderPlanParameters()}
              </VuiAccordion>
              <VuiSpacer size="m"/>
              <VuiAccordion
                header="Edit plan spec"
                isOpen={isPlanSpecAccordionOpen}
                setIsOpen={setIsPlanSpecAccordionOpen}
              >
                {renderPlanSpec()}
              </VuiAccordion>
            </VuiFlexItem>
            <VuiFlexItem>
              <VuiButtonPrimary className="w-28" color="neutral" onClick={updatePlan}>
                {isUpdating ? <VuiSpinner size="xs" /> : "Update"}
              </VuiButtonPrimary>
            </VuiFlexItem>
            <VuiSpacer size="l" />
          </VuiFlexContainer>
        </VuiFlexItem>
      </VuiFlexContainer>
    </VuiFlexContainer>
  );
};
