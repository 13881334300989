import { useEffect, useState } from "react";
import { VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { Job } from "../../utils/commonTypes";
import { listJobs } from "../../api/api";
import { useCustomerContext } from "../../utils/CustomerContext";
import { useLoginContext } from "../../utils/LoginContext";

const jobTypeToName = (typeId: number) => {
  if (typeId === 1) {
    return "Rebuild";
  } else if (typeId === 2) {
    return "S3 Scan";
  }
};

const getRunningJobs = (jobs: Array<Job>) => {
  return jobs.filter((item) => item.status === "started");
};

const getIdleJobs = (jobs: Array<Job>) => {
  return jobs.filter((item) => item.status !== "started");
};

export const CustomerJobs = () => {
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();

  useEffect(() => {
    setIsLoading(true);
    const fetchJobs = async () => {
      try {
        setJobs(await listJobs(customer.customerId, authToken));
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    };
    fetchJobs();
  }, [authToken, customer.customerId]);

  const columns = [
    {
      name: "id",
      header: {
        render: () => {
          return "Id";
        }
      },
      render: (job: Job) => {
        if (!job) return;
        return <>{job.jobId}</>;
      }
    },
    {
      name: "type",
      header: {
        render: () => {
          return "Type";
        }
      },
      render: (job: Job) => {
        if (!job) return;
        return <>{jobTypeToName(job.typeId)}</>;
      }
    },
    {
      name: "corpusId",
      header: {
        render: () => {
          return "Corpus ID";
        }
      },
      render: (job: Job) => {
        if (!job) return;
        return <>{job.corpusId}</>;
      }
    },
    {
      name: "status",
      header: {
        render: () => {
          return "Status";
        }
      },
      render: (job: Job) => {
        if (!job) return;
        return <>{job.status}</>;
      }
    },
    {
      name: "description",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (job: Job) => {
        if (!job) return;
        return <>{job.description}</>;
      }
    }
  ];

  const tableContent = !jobs?.length ? (
    <VuiText>
      <p>No jobs.</p>
    </VuiText>
  ) : undefined;

  if (isLoading && !jobs?.length) {
    return (
      <VuiText>
        Loading jobs, please wait ...
        <VuiSpinner size="m"></VuiSpinner>
      </VuiText>
    );
  }

  if (!jobs?.length) {
    return <VuiText>No jobs found for this customer.</VuiText>;
  }

  return (
    <div className="rounded-t-xl overflow-hidden">
      <div className="mb-4 text-base">
        <span className="font-bold">{jobs.length}</span> jobs found.{" "}
        <span className="font-bold">{getRunningJobs(jobs).length}</span> jobs running.{" "}
        <span className="font-bold">{getIdleJobs(jobs).length}</span> jobs idle.{" "}
      </div>
      <div>
        <VuiTable
          fluid
          idField={(job: Job) => job.jobId.toString()}
          columns={columns}
          rows={jobs}
          content={tableContent}
        />
      </div>
    </div>
  );
};
