import { Routes, Route, useLocation, Link } from "react-router-dom";
import { CustomerContextProvider } from "../utils/CustomerContext";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { Customer } from "./customer/Customer";
import { Header } from "./Header";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LoginContextProvider } from "../utils/LoginContext";
import { PlansContextProvider } from "../utils/PlansContext";
import { ToastContainer } from "react-toastify";
import { LandingPage } from "./LandingPage";
import { LinkProps, VuiContextProvider } from "@vectara/vectara-ui";

const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

export const App = () => {
  const location = useLocation();
  const linkProvider = (linkConfig: LinkProps) => {
    const { className, href, onClick, children, ...rest } = linkConfig;
    return (
      <Link className={className} to={href ?? ""} onClick={onClick} {...rest}>
        {children}
      </Link>
    );
  };
  const pathProvider = () => {
    return location.pathname;
  };

  return (
    <VuiContextProvider linkProvider={linkProvider} pathProvider={pathProvider}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <LoginContextProvider>
          <PlansContextProvider>
            <CustomerContextProvider>
              <ToastContainer />
              <Header />
              <Routes>
                <Route path="*" element={<LandingPage />} />
                <Route path="customer/:id/*" element={<Customer />} />
              </Routes>
            </CustomerContextProvider>
          </PlansContextProvider>
        </LoginContextProvider>
      </GoogleOAuthProvider>
    </VuiContextProvider>
  );
};
