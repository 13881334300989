import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { VuiFlexContainer, VuiFlexItem, VuiTextColor } from "@vectara/vectara-ui";
import { usePlansContext } from "../utils/PlansContext";
import { BiCaretDown } from "react-icons/bi";
import { useState } from "react";
import { CreatePlan } from "./createPlan/CreatePlan";
import "./Plans.scss";
import { useLoginContext } from "../utils/LoginContext";

export const Plans = () => {
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const { isAuthenticated } = useLoginContext();

  const { availablePlans } = usePlansContext();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getPlanRows = () => {
    return availablePlans.length > 0 ? (
      availablePlans.map((item, index) => (
        <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
          <td className="col-plan-name px-6 text-gray-700 py-2">{item.planName}</td>
          <td className="col-plan-desc px-6 text-gray-700 py-2">{item.planDescription}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={2} className="col-job-id px-6 py-2 italic text-gray-500">
          No plans found.
        </td>
      </tr>
    );
  };

  return (
    <VuiFlexContainer direction="column" className="ml-3 plans">
      {isAuthenticated ? (
        <>
          <Accordion expanded={expanded === "panelPlans"} onChange={handleChange("panelPlans")}>
            <AccordionSummary expandIcon={<BiCaretDown />}>
              <VuiTextColor color="primary">Available Plans</VuiTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <VuiFlexItem className="ml-15">
                <table className="plans-data-table table-fixed divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="col-plan-name px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase"
                      >
                        {" "}
                        Name{" "}
                      </th>
                      <th
                        scope="col"
                        className="col-plan-desc px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase"
                      >
                        {" "}
                        Description{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{getPlanRows()}</tbody>
                </table>
              </VuiFlexItem>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panelCreate"} onChange={handleChange("panelCreate")}>
            <AccordionSummary expandIcon={<BiCaretDown />}>
              <VuiTextColor color="primary">Create new plan</VuiTextColor>
            </AccordionSummary>
            <AccordionDetails>
              <CreatePlan />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <VuiFlexItem className="ml-10"> Please sign in to view plans. </VuiFlexItem>
      )}
    </VuiFlexContainer>
  );
};
