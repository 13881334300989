import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./components/App";
import "./index.css";

const rootEl = document.getElementById("root");

if (rootEl) {
  const root = createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );
}
