import { Corpus } from "../../utils/commonTypes";
import "./Customer.css";
import { useEffect, useState } from "react";
import { VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { useCustomerContext } from "../../utils/CustomerContext";
import { useLoginContext } from "../../utils/LoginContext";
import { listCorpora } from "../../api/api";

const getActiveCorpora = (corpora: Array<Corpus>) => {
  return corpora.filter((item) => item.enabled);
};

const getInactiveCorpora = (corpora: Array<Corpus>) => {
  return corpora.filter((item) => !item.enabled);
};

export const CustomerCorpora = () => {
  const [corpora, setCorpora] = useState<Array<Corpus>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();

  useEffect(() => {
    setIsLoading(true);
    const fetchCorpora = async () => {
      try {
        setCorpora(await listCorpora(customer.customerId, authToken));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCorpora();
  }, [authToken, customer.customerId]);

  const columns = [
    {
      name: "id",
      header: {
        render: () => {
          return "ID";
        }
      },
      render: (corpus: Corpus) => {
        if (!corpus) return;
        return <>{corpus.corpusId}</>;
      }
    },
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (corpus: Corpus) => {
        if (!corpus) return;
        return <>{corpus.corpusName}</>;
      }
    },
    {
      name: "enabled",
      header: {
        render: () => {
          return "Enabled";
        }
      },
      render: (corpus: Corpus) => {
        if (!corpus) return;
        return <>{corpus.enabled ? "True" : "False"}</>;
      }
    }
  ];

  const tableContent = !corpora?.length ? (
    <VuiText>
      <p>No corpora found.</p>
    </VuiText>
  ) : undefined;

  if (isLoading && !corpora?.length) {
    return (
      <VuiText>
        Loading corpora, please wait ...
        <VuiSpinner size="m" />
      </VuiText>
    );
  }

  if (!corpora?.length) {
    <VuiText>No corpora found for this customer.</VuiText>;
  }

  return (
    <div className="rounded-t-xl overflow-hidden">
      <div className="mb-4 text-base">
        <span className="font-bold">{corpora.length}</span> corpora found.{" "}
        <span className="font-bold">{getActiveCorpora(corpora).length}</span> enabled,{" "}
        <span className="font-bold">{getInactiveCorpora(corpora).length}</span> disabled.
      </div>
      <VuiTable
        fluid
        idField={(corpus: Corpus) => corpus.corpusId.toString()}
        columns={columns}
        rows={corpora}
        content={tableContent}
      />
    </div>
  );
};
