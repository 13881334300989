import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { VuiFlexContainer, VuiLabel } from "@vectara/vectara-ui";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { FreeTrialPlanParameters } from "../../../utils/commonTypes";

type Props = {
    parameters: FreeTrialPlanParameters;
    setParameters: (parameters: FreeTrialPlanParameters) => void;
};

export const FreeTrialParameters = ({ parameters, setParameters }: Props) => {
  const minExpiry = useMemo(() => dayjs.unix(parameters.expiryEpochSecond), []);

  const handleExpiryChange = (expiry: Dayjs | null) => {
    if (!expiry) {
      return;
    }
    
    setParameters({ expiryEpochSecond: expiry.unix() });
  };

  return (
    <VuiFlexContainer direction="column">
        <VuiLabel>Expiry</VuiLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["year", "month", "day"]}
            value={dayjs.unix(parameters.expiryEpochSecond)}
            minDate={minExpiry}
            onChange={handleExpiryChange}
          />
        </LocalizationProvider>
    </VuiFlexContainer>
  );
};
