import { useEffect, useState } from "react";
import { FaissServer } from "../../../utils/commonTypes";
import { VuiCheckbox, VuiFlexContainer, VuiSpacer, VuiTable, VuiText, VuiTextColor } from "@vectara/vectara-ui";
import { readAvailableFaissServers } from "../../../api/api";
import { useLoginContext } from "../../../utils/LoginContext";

type Props = {
  selected?: Array<FaissServer>;
  setSelected: (selected: Array<FaissServer>) => void;
};

export const NewPlanConfig = ({ selected, setSelected }: Props) => {
  const { authToken } = useLoginContext();
  const [availableServers, setAvailableServers] = useState<Array<FaissServer>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFaissServers = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const servers = await readAvailableFaissServers(authToken);
    setAvailableServers(filterServers(servers));
    setIsLoading(false);
  };

  const filterServers = (servers: FaissServer[]): FaissServer[] => {
    // We want to remove servers whose host name contains "free"
    // We also want to remove servers whose Labels contains "free_tier": "true" or "sharded":"true" or "test":"true"
    return servers.filter((item: FaissServer) => {
      if (item.host && item.host.includes("free")) return false;
      if (
        item.labels &&
        (item.labels.includes('"free_tier": "true"') ||
          item.labels.includes('"sharded": "true"') ||
          item.labels.includes('"test": "true"') ||
          item.labels.includes('"dedicated": "true"'))
      )
        return false;
      return true;
    });
  };

  useEffect(
    () => {
      getFaissServers();
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onServerChanged = (server: FaissServer) => {
    if (!selected) return;
    if (selected.find((item) => item === server)) {
      setSelected(selected.filter((item) => item !== server));
    } else {
      setSelected([...selected, server]);
    }
  };

  const columns = [
    {
      name: "select",
      header: {
        render: () => {
          return "";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return (
          <VuiCheckbox
            checked={selected?.find((item) => item === server) !== undefined}
            onChange={() => onServerChanged(server)}
          />
        );
      }
    },
    {
      name: "id",
      header: {
        render: () => {
          return "Id";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.id;
      }
    },
    {
      name: "host",
      header: {
        render: () => {
          return "Host";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.host;
      }
    },
    {
      name: "labels",
      header: {
        render: () => {
          return "Labels";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.labels;
      }
    },
    {
      name: "capacityGB",
      header: {
        render: () => {
          return "Capacity (GB)";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.capacityGB;
      }
    },
    {
      name: "reservedGB",
      header: {
        render: () => {
          return "Reserved (GB)";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.reservedGB;
      }
    },
    {
      name: "availableGB",
      header: {
        render: () => {
          return "Available (GB)";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.availableGB;
      }
    },
    {
      name: "customerCount",
      header: {
        render: () => {
          return "Customers";
        }
      },
      render: (server: FaissServer) => {
        if (!server) return;
        return server.customersCount;
      }
    }
  ];

  const tableContent = !availableServers?.length ? (
    isLoading ? (
      <VuiText>
        <p>Loading Faiss Servers, please wait ...</p>
      </VuiText>
    ) : (
      <VuiText>
        <p>Could not load Faiss Servers.</p>
      </VuiText>
    )
  ) : undefined;

  return (
    <VuiFlexContainer direction="column">
      <VuiSpacer size="m" />
      <VuiText className="switch-description">
        <VuiTextColor color="accent">
          Note: Distribute the customers across servers while making sure the server has available capacity.
        </VuiTextColor>
      </VuiText>
      <VuiSpacer size="l" />
      <VuiText>
        <h3>Available Faiss Servers</h3>
      </VuiText>
      <VuiTable
        fluid
        idField={(server: FaissServer) => server.id.toString()}
        columns={columns}
        rows={availableServers}
        content={tableContent}
      />
    </VuiFlexContainer>
  );
};
