import { useState, useEffect } from "react";
import { VuiSpacer, VuiText } from "@vectara/vectara-ui";
import { useLoginContext } from "../../../utils/LoginContext";
import { listRoles, listUsers } from "../../../api/api";
import { Role, User } from "../../../utils/commonTypes";
import { UserManagement } from "../user";
import { RoleManagement } from "../role";
import { toast } from "react-toastify";

export const ManagementPage = () => {
  const { authToken, authUser } = useLoginContext();
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Check permissions
  const hasManageUserPermission = authUser.permissions.includes("PERMISSION_MANAGE_USERS");
  const hasManageRolesPermission = authUser.permissions.includes("PERMISSION_MANAGE_ROLES");

  // Fetch users and roles on component mount
  useEffect(() => {
    if (!hasManageUserPermission && !hasManageRolesPermission) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [usersData, rolesData] = await Promise.all([
          hasManageUserPermission ? listUsers(authToken) : [],
          listRoles(authToken)
        ]);
        setUsers(usersData);
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load management data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [authToken, hasManageUserPermission, hasManageRolesPermission]);

  // Refresh users data
  const refreshUsers = async () => {
    if (!hasManageUserPermission) return;
    try {
      const updatedUsers = await listUsers(authToken);
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error refreshing users:", error);
      toast.error("Failed to refresh users");
    }
  };

  // Refresh roles data
  const refreshRoles = async () => {
    try {
      const updatedRoles = await listRoles(authToken);
      setRoles(updatedRoles);
    } catch (error) {
      console.error("Error refreshing roles:", error);
      toast.error("Failed to refresh roles");
    }
  };

  if (!hasManageUserPermission && !hasManageRolesPermission) {
    return <VuiText>You do not have permission to access this page.</VuiText>;
  }

  return (
    <div>
      {hasManageUserPermission && (
        <>
          <UserManagement users={users} roles={roles} isLoading={isLoading} onUserUpdated={refreshUsers} />
          <VuiSpacer size="xl" />
        </>
      )}

      {hasManageRolesPermission && (
        <>
          <RoleManagement roles={roles} isLoading={isLoading} onRoleUpdated={refreshRoles} />
        </>
      )}
    </div>
  );
};

export default ManagementPage;
