import { FormControl, Input, InputAdornment, InputLabel } from "@mui/material";
import { PricingPlan } from "../../utils/commonTypes";
import { PlanActions, PlanItem } from "./CreatePlanConstants";
import { VuiFlexContainer, VuiFlexItem, VuiText } from "@vectara/vectara-ui";
import { BytesToMebiBytes, MebiBytesToBytes } from "../../utils/commonFunctions";

type Props = {
  pricingPlan: PricingPlan;
  updatePricingPlan: React.Dispatch<PlanActions>;
};

export const PlanOverage = ({ pricingPlan, updatePricingPlan }: Props) => {
  return (
    <VuiFlexContainer direction="column" className="mt-5 ml-2">
      <VuiText className="font-bold">Overage specifications</VuiText>
      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="query-overage-price">Query overage price</InputLabel>
            <Input
              id="query-overage-price"
              value={pricingPlan.queryOverage.price.amount}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_OVERAGE_PRICE", valueType: PlanItem.QUERY, price: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="query-overage-number">for number of queries</InputLabel>
            <Input
              id="query-overage-number"
              value={pricingPlan.queryOverage.amount}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_AMOUNT",
                  valueType: PlanItem.QUERY,
                  amount: Number(e.target.value)
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>

      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="ingest-overage-price">Ingest overage price</InputLabel>
            <Input
              id="ingest-overage-price"
              value={pricingPlan.indexingOverage.price.amount}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_OVERAGE_PRICE", valueType: PlanItem.INGEST, price: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="ingest-overage-number">for ingest bytes</InputLabel>
            <Input
              id="ingest-overage-number"
              value={BytesToMebiBytes(pricingPlan.indexingOverage.amount)}
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_AMOUNT",
                  valueType: PlanItem.INGEST,
                  amount: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>

      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="storage-overage-price">Storage overage price</InputLabel>
            <Input
              id="storage-overage-price"
              value={pricingPlan.storageOverage.price.amount}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_PRICE",
                  valueType: PlanItem.STORAGE,
                  price: e.target.value
                })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="storage-overage-number">for storage bytes</InputLabel>
            <Input
              id="storage-overage-number"
              value={BytesToMebiBytes(pricingPlan.storageOverage.amount)}
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_AMOUNT",
                  valueType: PlanItem.STORAGE,
                  amount: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>

      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="corpora-overage-price">Corpora overage price</InputLabel>
            <Input
              id="corpora-overage-price"
              value={pricingPlan.corpusOverage.price.amount}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_PRICE",
                  valueType: PlanItem.CORPORA,
                  price: e.target.value
                })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="corpora-overage-number">for number of corpora</InputLabel>
            <Input
              id="corpora-overage-number"
              value={pricingPlan.corpusOverage.amount}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_AMOUNT",
                  valueType: PlanItem.CORPORA,
                  amount: Number(e.target.value)
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>

      <VuiFlexItem>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="user-overage-price">User overage price</InputLabel>
            <Input
              id="user-overage-price"
              value={pricingPlan.userOverage.price.amount}
              endAdornment={<InputAdornment position="end">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_OVERAGE_PRICE", valueType: PlanItem.USERS, price: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "35ch", m: 1, fontSize: 12 }}>
            <InputLabel htmlFor="user-overage-number">for number of users</InputLabel>
            <Input
              id="user-overage-number"
              value={pricingPlan.userOverage.amount}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_OVERAGE_AMOUNT",
                  valueType: PlanItem.USERS,
                  amount: Number(e.target.value)
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};
