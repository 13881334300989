import { usePlansContext } from "../../../utils/PlansContext";
import { Plan } from "../../../utils/commonTypes";
import { VuiCheckbox, VuiTable, VuiText } from "@vectara/vectara-ui";

type Props = {
  selected?: Plan;
  setSelected: (plan: Plan) => void;
};

const filterPlans = (plans: Plan[]): Plan[] => {
  const allowedPlans = ["Free Trial", "Scale - Trial", "Scale - Custom"];
  return plans.filter((item: Plan) => {
    return allowedPlans.includes(item.planName);
  });
};

export const AvailablePlans = ({ selected, setSelected }: Props) => {
  const { availablePlans } = usePlansContext();

  const plans = filterPlans(availablePlans);

  const columns = [
    {
      name: "select",
      header: {
        render: () => {
          return "";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return (
          <VuiCheckbox
            checked={selected?.planId === plan.planId}
            onChange={() => setSelected(plan)}
          />
        );
      }
    },
    {
      name: "id",
      header: {
        render: () => {
          return "Id";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planId;
      }
    },
    {
      name: "name",
      header: {
        render: () => {
          return "Name";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planName;
      }
    },
    {
      name: "description",
      header: {
        render: () => {
          return "Description";
        }
      },
      render: (plan: Plan) => {
        if (!plan) return;
        return plan.planDescription;
      }
    }
  ];

  const tableContent = !plans?.length ? (
    <VuiText>
      <p>Could not load plans.</p>
    </VuiText>
  ) : undefined;

  return (
      <VuiTable
        fluid
        idField={(plan: Plan) => plan.planId.toString()}
        columns={columns}
        rows={plans}
        content={tableContent}
      />
  );
};
