import { createContext, ReactNode, useContext, useState } from "react";
import { AuthUser } from "./commonTypes";

interface LoginContextType {
  authToken: string;
  setAuthToken: (idToken: string) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  authUser: AuthUser;
  setAuthUser: (authUser: AuthUser) => void;
}

const LoginContext = createContext<LoginContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const LoginContextProvider = ({ children }: Props) => {
  const [authUser, setAuthUser] = useState<AuthUser>({
    email: "",
    role: "",
    permissions: []
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState("");

  return (
    <LoginContext.Provider
      value={{
        authToken,
        setAuthToken,
        isAuthenticated,
        setIsAuthenticated,
        authUser,
        setAuthUser
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error("useLoginContext must be used within a LoginContextProvider");
  }
  return context;
};
