import { Autocomplete, FormControl, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { VuiFlexContainer, VuiText } from "@vectara/vectara-ui";
import { PlanActions, PlanItem } from "./CreatePlanConstants";
import { PricingPlan, PricingPlanPeriod } from "../../utils/commonTypes";
import { BytesToMebiBytes, MebiBytesToBytes } from "../../utils/commonFunctions";

type Props = {
  pricingPlan: PricingPlan;
  updatePricingPlan: React.Dispatch<PlanActions>;
};

const valueFromPeriod = (period: PricingPlanPeriod): string => {
  if (period.days > 0) {
    return "day";
  } else if (period.months > 0) {
    return "month";
  } else if (period.years > 0) {
    return "year";
  } else {
    return "";
  }
};

export const PlanIncludes = ({ pricingPlan, updatePricingPlan }: Props) => {
  return (
    <VuiFlexContainer direction="column" className="mt-10">
      <VuiText className="font-bold ml-5">Plan Includes</VuiText>

      <VuiFlexContainer direction="column" className="ml-2 mt-10">
        <VuiFlexContainer direction="row" className="ml-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="base-price">Monthly base price</InputLabel>
            <Input
              id="base-price"
              value={pricingPlan.base?.amount.amount}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_BASE_AMOUNT", valueType: PlanItem.BASE, amount: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "18ch", ml: 2, mb: 2 }}>
            <Autocomplete
              id="plan-period"
              options={["month", "year", "day"]}
              value={valueFromPeriod(pricingPlan.base.period)}
              renderInput={(params) => <TextField {...params} label="Period" />}
              onChange={(e, value) =>
                updatePricingPlan({ type: "UPDATE_PERIOD", valueType: PlanItem.BASE, period: value || "" })
              }
            />
          </FormControl>
        </VuiFlexContainer>

        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-queries">Included Queries</InputLabel>
            <Input
              id="included-queries"
              value={pricingPlan.includedQueries.amount}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_BASE_AMOUNT", valueType: PlanItem.QUERY, amount: e.target.value })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "18ch", ml: 2, mb: 2 }}>
            <Autocomplete
              id="plan-period"
              options={["month", "year", "day"]}
              value={valueFromPeriod(pricingPlan.includedQueries.period)}
              renderInput={(params) => <TextField {...params} label="Period" />}
              onChange={(e, value) =>
                updatePricingPlan({ type: "UPDATE_PERIOD", valueType: PlanItem.QUERY, period: value || "" })
              }
            />
          </FormControl>
        </VuiFlexContainer>

        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-summarizations">Included Summarizations</InputLabel>
            <Input
              id="included-summarizations"
              value={pricingPlan.includedSummarizations.amount}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_BASE_AMOUNT",
                  valueType: PlanItem.SUMMARIZATIONS,
                  amount: e.target.value
                })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "18ch", ml: 2, mb: 2 }}>
            <Autocomplete
              id="plan-period"
              options={["month", "year", "day"]}
              value={valueFromPeriod(pricingPlan.includedSummarizations.period)}
              renderInput={(params) => <TextField {...params} label="Period" />}
              onChange={(e, value) =>
                updatePricingPlan({ type: "UPDATE_PERIOD", valueType: PlanItem.SUMMARIZATIONS, period: value || "" })
              }
            />
          </FormControl>
        </VuiFlexContainer>

        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-indexing">Included Indexing</InputLabel>
            <Input
              id="included-indexing"
              value={BytesToMebiBytes(pricingPlan.includedIndexing.amount)}
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_BASE_AMOUNT",
                  valueType: PlanItem.INGEST,
                  amount: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
          <FormControl sx={{ width: "18ch", ml: 2, mb: 2 }}>
            <Autocomplete
              id="plan-period"
              options={["month", "year", "day"]}
              value={valueFromPeriod(pricingPlan.includedIndexing.period)}
              renderInput={(params) => <TextField {...params} label="Period" />}
              onChange={(e, value) =>
                updatePricingPlan({ type: "UPDATE_PERIOD", valueType: PlanItem.INGEST, period: value || "" })
              }
            />
          </FormControl>
        </VuiFlexContainer>

        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-storage">Included Storage</InputLabel>
            <Input
              id="included-storage"
              value={BytesToMebiBytes(pricingPlan.includedStorage)}
              endAdornment={<InputAdornment position="end">MiB</InputAdornment>}
              onChange={(e) =>
                updatePricingPlan({
                  type: "UPDATE_BASE_AMOUNT",
                  valueType: PlanItem.STORAGE,
                  amount: MebiBytesToBytes(Number(e.target.value))
                })
              }
            />
          </FormControl>
        </VuiFlexContainer>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-corpora">Included Corpora</InputLabel>
            <Input
              id="included-corpora"
              value={pricingPlan.includedCorpora}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_BASE_AMOUNT", valueType: PlanItem.CORPORA, amount: e.target.value })
              }
            />
          </FormControl>
        </VuiFlexContainer>
        <VuiFlexContainer direction="row" className="ml-2 mt-2">
          <FormControl sx={{ width: "18ch", ml: 1, mb: 2 }}>
            <InputLabel htmlFor="included-users">Included Users</InputLabel>
            <Input
              id="included-users"
              value={pricingPlan.includedUsers}
              onChange={(e) =>
                updatePricingPlan({ type: "UPDATE_BASE_AMOUNT", valueType: PlanItem.USERS, amount: e.target.value })
              }
            />
          </FormControl>
        </VuiFlexContainer>
      </VuiFlexContainer>
    </VuiFlexContainer>
  );
};
