import { useEffect, useState } from "react";
import { VuiFlexContainer, VuiSetting, VuiSpacer, VuiSpinner, VuiTable, VuiText } from "@vectara/vectara-ui";
import { formatBytes } from "../../utils/commonFunctions";
import { Stats } from "../../utils/commonTypes";
import "./Customer.css";
import { useCustomerContext } from "../../utils/CustomerContext";
import { useLoginContext } from "../../utils/LoginContext";
import { readCustomerStats } from "../../api/api";

const getDateRange = (statItem: Stats) => {
  const startDate = new Date(statItem.startEpoch * 1000);
  const endDate = new Date(statItem.endEpoch * 1000);
  // Return a string like "02-Jan-21 -> 02-Feb-21"
  return `${startDate.getDate()}-${startDate.toLocaleString("default", {
    month: "short"
  })}-${startDate.getFullYear()} to ${endDate.getDate()}-${endDate.toLocaleString("default", {
    month: "short"
  })}-${endDate.getFullYear()}`;
};

export const CustomerStats = () => {
  const { customer } = useCustomerContext();
  const { authToken } = useLoginContext();

  const [stats, setStats] = useState<Array<Stats>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [anchorBasedStats, setAnchorBasedStats] = useState<boolean>(true);

  const fetchCustomerStats = async () => {
    setIsLoading(true);
    try {
      setStats([]);
      const stats = await readCustomerStats(customer.customerId, authToken, 6, anchorBasedStats);
      setStats(stats);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerStats();
    // eslint-disable-next-line
  }, [authToken, customer.customerId, anchorBasedStats]);

  const handleToggle = () => {
    if (isLoading) return;
    setAnchorBasedStats(!anchorBasedStats);
  };

  const toggleButton = (
    <VuiSetting
      title="Type of stats, anchor-based or month-based"
      label={anchorBasedStats ? "Anchor based" : "Month based"}
      isEnabled={anchorBasedStats}
      onToggle={handleToggle}
    ></VuiSetting>
  );

  const columns = [
    {
      name: "period",
      header: {
        render: () => {
          return "Time Period";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{getDateRange(stats)}</>;
      }
    },
    {
      name: "queries",
      header: {
        render: () => {
          return "Queries";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{stats.queryCount.toLocaleString()}</>;
      }
    },
    {
      name: "summarizations",
      header: {
        render: () => {
          return "Generative Requests";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{stats.summarizations.toLocaleString()}</>;
      }
    },
    {
      name: "gpt4GenerativeRequests",
      header: {
        render: () => {
          return "GPT4 Generative Requests";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{stats.gpt4GenerativeRequests.toLocaleString()}</>;
      }
    },
    {
      name: "bytes",
      header: {
        render: () => {
          return "Bytes Ingested";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{formatBytes(stats.bytesIngested)}</>;
      }
    },
    {
      name: "storage",
      header: {
        render: () => {
          return "Storage Used";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{formatBytes(stats.storageUsed)}</>;
      }
    },
    {
      name: "users",
      header: {
        render: () => {
          return "Users";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{stats.users}</>;
      }
    },
    {
      name: "corpora",
      header: {
        render: () => {
          return "Corpora";
        }
      },
      render: (stats: Stats) => {
        if (!stats) return;
        return <>{stats.corpora}</>;
      }
    }
  ];

  const tableContent = !stats?.length ? (
    <VuiText>
      <p>No stats found.</p>
    </VuiText>
  ) : undefined;

  if (isLoading && !stats?.length) {
    return (
      <VuiFlexContainer direction="column">
        {toggleButton}
        <VuiSpacer size="l" />
        <VuiText>
          <i>Loading stats, please wait ...</i>
        </VuiText>
        <VuiSpinner size="m" />
      </VuiFlexContainer>
    );
  }

  if (!stats?.length) {
    return (
      <VuiFlexContainer direction="column">
        {toggleButton}
        <VuiSpacer size="l" />
        <VuiText>Could not load stats.</VuiText>
        <VuiSpinner size="m"></VuiSpinner>
      </VuiFlexContainer>
    );
  }

  return (
    <VuiFlexContainer direction="column">
      {toggleButton}
      <div className="rounded-t-xl overflow-hidden">
        <VuiTable
          fluid
          idField={(stat: Stats) => stat.startEpoch.toString()}
          columns={columns}
          rows={stats}
          content={tableContent}
        />{" "}
      </div>
    </VuiFlexContainer>
  );
};
